import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChatUtils } from '../utilities/utils';
import { ReplaySubject, Observable, BehaviorSubject } from 'rxjs/Rx';
import { Message } from '../interfaces/message';
import { PusherService } from './pusher.service';
import * as config from '../../../../providers/config';
import { AppStateService } from 'src/app/services/app-state.service';

@Injectable()
export class ChatPanelService {
  // globalvariables

  contacts: any[];
  chats: any[];
  user: any;
  messagesStream = new ReplaySubject<Message>(1);
  contactsDataUrl = 'assets/mockup/contacts.json';
  userDataUrl = 'assets/mockup/users.json';
  chatsDataUrl = 'assets/mockup/chats.json';
  private isOddValue = false;
  private isOdd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isOdd$: Observable<boolean> = this.isOdd.asObservable();

  constructor(
    private _httpClient: HttpClient,
    public pusherService: PusherService,
    public appState: AppStateService
  ) { }

  /**
   * initialize the channel
   * @param id pass channel id
   */

  // Old code
  // initialize(id) {
  //   this.pusherService.messagesChannel = this.pusherService.pusher.subscribe(
  //     'private-crecientech-chat' + id
  //   );
  //   this.pusherService.messagesChannel.bind(
  //     'client-new-message' + id,
  //     (message) => {
  //       this.emitNewMessage(message);
  //     }
  //   );
  // };

  // New Code
  // Initializes the Pusher channel with the given ID
  // initialize(id) {
  //   // Check if there's already a subscribed messagesChannel
  //   if (this.pusherService.messagesChannel) {
  //     console.log(`Unbinding all events from channel: ${this.pusherService.messagesChannel.name}`);
  //     // Unbind all events to prevent duplicate event handling
  //     this.pusherService.messagesChannel.unbind_all();

  //     console.log(`Unsubscribing from channel: ${this.pusherService.messagesChannel.name}`);
  //     // Unsubscribe from the current channel
  //     this.pusherService.pusher.unsubscribe(this.pusherService.messagesChannel.name);
  //   }

  //   const channelName = 'private-crecientech-chat' + id;
  //   console.log(`Subscribing to new channel: ${channelName}`);
  //   // Subscribe to the new channel with the provided ID
  //   this.pusherService.messagesChannel = this.pusherService.pusher.subscribe(channelName);

  //   console.log(`Binding to event: client-new-message${id}`);
  //   // Bind the 'client-new-message' event to the new channel
  //   this.pusherService.messagesChannel.bind(
  //     'client-new-message' + id,
  //     (message) => {
  //       console.log(`New message received on channel ${channelName}: `, message);
  //       // Emit the received message to the message stream
  //       this.emitNewMessage(message);
  //     }
  //   );
  // };

   /* ---------------------------------------------------------------------------------------------------
    ** Method Subscribe to channelId Starts
    ** -------------------------------------------------------------------------------------------*/
  //  New Method to Unsubscribe all channelId except personChannelId
  initialize(id) {
    // Get the current personChannelId that should remain subscribed
    const personChannelId = this.appState.globalData.personChannelId;
    const currentChannelName = 'private-crecientech-chat' + personChannelId;

    // Iterate over the channels in the Pusher instance
    Object.keys(this.pusherService.pusher.channels.channels).forEach((channelName) => {
      // If the channel is not the current person's channel, unsubscribe
      if (channelName !== currentChannelName) {
        console.log(`Unbinding all events from channel: ${channelName}`);
        this.pusherService.pusher.channels.channels[channelName].unbind_all();

        console.log(`Unsubscribing from channel: ${channelName}`);
        this.pusherService.pusher.unsubscribe(channelName);
      }
    });

    const newChannelName = 'private-crecientech-chat' + id;
    console.log(`Subscribing to new channel: ${newChannelName}`);

    // Subscribe to the new channel with the provided ID
    this.pusherService.messagesChannel = this.pusherService.pusher.subscribe(newChannelName);

    console.log(`Binding to event: client-new-message${id}`);
    // Bind the 'client-new-message' event to the new channel
    this.pusherService.messagesChannel.bind(
      'client-new-message' + id,
      (message) => {
        console.log(`New message received on channel ${newChannelName}: `, message);
        // Emit the received message to the message stream
        this.emitNewMessage(message);
      }
    );
  }

  unsubscribeChannelId(){
     // Get the current personChannelId that should remain subscribed
     const personChannelId = this.appState.globalData.personChannelId;
     const currentChannelName = 'private-crecientech-chat' + personChannelId;

     // Iterate over the channels in the Pusher instance
     Object.keys(this.pusherService.pusher.channels.channels).forEach((channelName) => {
      // If the channel is not the current person's channel, unsubscribe
      if (channelName !== currentChannelName) {
        console.log(`Unbinding all events from channel: ${channelName}`);
        this.pusherService.pusher.channels.channels[channelName].unbind_all();

        console.log(`Unsubscribing from channel: ${channelName}`);
        this.pusherService.pusher.unsubscribe(channelName);
      }
    });
  }




  /**
   * send new message to particular channel
   * @param message new message data
   */

  send(message: Message) {
    this.pusherService.messagesChannel.trigger(
      'client-new-message' + message.channelId,
      message
    );
    this.emitNewMessage(message);
    console.log("message", message);

  }

  /**
   * emitNewMessage to particular channel
   * @param message new message data
   */

  emitNewMessage(message: Message) {
    this.messagesStream.next(message);
  }

  /**
   * update the channel status
   * @param senderId sender id
   * @param receiverId reciver id
   */

  updateMessageStatus(senderId, receiverId): Promise<any> {
    var params = {
      senderId: senderId,
      receiverId: receiverId,
    };
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEMESSAGESTATUS, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * get all contacts based on id
   * @param params user id
   */

  getContacts(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GETCONTACTS, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * get chat data based on id
   * @param params
   */

  getChatData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.RETRIVECHATS, params)
        .subscribe((response: any) => {
          console.log(response);
          resolve(response);
        }, reject);
    });
  }

  /**
   * get individual contacts
   * @param params
   */

  getContactsInd(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GETCONTACTSINV, params)
        .subscribe((response: any) => {
          console.log(response);
          resolve(response);
        }, reject);
    });
  }

  /**
   * create chat data
   * @param params
   */

  createChatData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.CREATECHATDATA, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * update channel id
   * @param params
   */

  updateChannelId(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATECHANNELID, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * active channels count
   * @param params
   */

  getActiveChannelCount(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GETACTIVECHANNELCOUNT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * create new channel
   * @param params
   */

  createChannel(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.CREATECHANNEL, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * create channel data
   * not using
   */

  createChannelData() {
    var params = {
      senderId: this.appState.decryptedDataValue('personId'),
      receiverId: this.appState.receiverId,
    };
    this.createChannel(params)
      .then((result) => {
        console.log('channel created : ' + JSON.stringify(result));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  /**
   * togglr booleaqn value if data changes
   * @param data to get sync data
   */

  toggleBooleanValue(data) {
    console.log('data toggle!');
    this.isOdd.next(data);
  }
}
