import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as config from '../../../providers/config';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { AppStateService } from 'src/app/services/app-state.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit {


  /* ----------------------------------------------------------------------------------
  ** Global varibles
  ------------------------------------------------------------------------------------*/
  // LicenseEdit: boolean = false;
  uploadUrl = config.UPLOADTOS3MOBILE;
  @Output() imageUrl: EventEmitter<any> = new EventEmitter<any>();
  image = '';
  @Input() licenseEdit: boolean = false;
  @Input() fileType: any;



  /* ----------------------------------------------------------------------------------
  ** constructor
  ------------------------------------------------------------------------------------*/

  constructor(private camera: Camera,
    private transfer: FileTransfer,
    public appStateService: AppStateService,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private platform: Platform) {
    // console.log("File Type in the Constructor", this.fileType);
  }

  /* ----------------------------------------------------------------------------------
  ** ngOnInit
  ------------------------------------------------------------------------------------*/

  ngOnInit() {
    // console.log("File Type in the ngOnInit()", this.fileType);
    // console.log(this.appStateService.globalData.imageURL)
  }

  /* ----------------------------------------------------------------------------------
  ** Method to capture or get image from mobile
  ------------------------------------------------------------------------------------*/

  uploadImage() {
    // alert('p');
    // console.log("File Type in the begining of uploadImage() Method", this.fileType);

    if (this.fileType == 'img') {
      var options: CameraOptions = {
        quality: 100,
        mediaType: this.camera.MediaType.PICTURE,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      };
    } else if (this.fileType == 'video') {
      var options: CameraOptions = {
        quality: 100,
        mediaType: this.camera.MediaType.VIDEO,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      };
    } else {
      var options: CameraOptions = {
        quality: 100,
        mediaType: this.camera.MediaType.PICTURE,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      };
    }
    this.camera.getPicture(options).then(
      (imageData) => {
        console.log("ImageData", imageData)
        console.log("File Extension Name", imageData?.split('.').pop())
        if(imageData === null){
          this.fileAlert();
        }else{
          this.uploadToserver(imageData);
        }

      },
      ((err) => {
        this.presentAlert()
      })
    );
  }

  /* ----------------------------------------------------------------------------------
  **  Method to show loading spinnier
  ------------------------------------------------------------------------------------*/
  async presentLoading() {
    console.log('starting loading');
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      spinner: "crescent",
      message: 'Loading...',
    });
    return await loading.present();
  }

  /* ----------------------------------------------------------------------------------
  ** Method to upload image to server
  ------------------------------------------------------------------------------------*/
  async uploadToserver(imageData) {
    // console.log("File Type in the begining of uploadToserver(imageData) Method", this.fileType);
    const loading = await this.presentLoading();
    if (this.platform.is('ios') && imageData.indexOf('file:///') === -1) {
        imageData = 'file://' + imageData;
    }
    var url = this.uploadUrl;
    var filename = imageData.substr(imageData.lastIndexOf('/') + 1);

    if (this.fileType == 'img') {
      var options: FileUploadOptions = {
        fileKey: 'ionicfile',
        fileName: 'ionicfile',
        chunkedMode: false,
        mimeType: 'image/jpeg',
      };
    } else if (this.fileType == 'video') {
      var options: FileUploadOptions = {
        fileKey: 'ionicfile',
        fileName: 'ionicfile',
        chunkedMode: false,
        mimeType: 'video/mp4',
      };
    } else {
      var options: FileUploadOptions = {
        fileKey: 'ionicfile',
        fileName: 'ionicfile',
        chunkedMode: false,
        mimeType: 'image/jpeg',
      };
    }

    const fileTransfer: FileTransferObject = this.transfer.create();
    fileTransfer.upload(imageData, url, options).then(
      (data) => {
        // alert(data.response)
        // console.log(data.response)
        var res1 = JSON.parse(data.response);
        // this.appStateService.signupDetails.imageUrl = res1.s3FileLocation
        console.log(res1.s3FileLocation)
        if (!this.licenseEdit) { this.appStateService.globalData.imageURL = res1.s3FileLocation }
        this.imageUrl.emit(res1.s3FileLocation);

        // this.image = res1.s3FileLocation;
        this.loadingController.dismiss();
      },
      (err) => {
        this.loadingController.dismiss();
        this.presentAlert();
      }
    );
  }



  /* ----------------------------------------------------------------------------------
    **  Method to show alert message
  ------------------------------------------------------------------------------------*/
  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Error in uploading file try again later !',
      // subHeader: 'Error in uploading image try again later !',
      // message: 'Error in uploading image try again later !.',
      buttons: ['OK']
    });

    await alert.present();
  }

  async fileAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Documents Not Supported in Mobile Application',
      // subHeader: 'Error in uploading image try again later !',
      message: 'Please Use the Web App to Upload Documents',
      buttons: ['OK']
    });

    await alert.present();
  }

  // triggerFileInput() {
  //   const fileInput = document.getElementById('uploadMobile') as HTMLElement;
  //   fileInput.click();
  // }
}
