import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AppStateService } from '../../services/app-state.service';
const URL = 'api/attachment/uploadDirectToS3';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-single-attachment',
  templateUrl: './single-attachment.component.html',
  styleUrls: ['./single-attachment.component.scss'],
})
export class SingleAttachmentComponent implements OnInit {
  // global variables

  imageDataAssign: any;
  image = 'file_upload_label';
  showProgress = false;
  allowedType: any = [];
  uploadTitle = "";
  // allowedType: any = "";
  // input and output
  public uUploader: FileUploader;

  @Output() imageUrl: EventEmitter<any> = new EventEmitter<any>();
  @Input('imageData') imageData;
  @Input() uImagePathParam: any;
  // @Input() fileType: string;
  // fileType: string;
  @Input()
  set fileType(fileType: any) {
    if (fileType == 'doc') {
      this.allowedType = [
        'application/pdf',
// --------------------------------------- Removed Spreadsheet File Types to Upload ---------------------------------------------
        // 'application/vnd.ms-excel',
        // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        // 'application/vnd.oasis.opendocument.spreadsheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.text',
        'image/gif',
        'image/jpeg',
        'image/png',
      ];
      this.uUploader = new FileUploader({
        url: URL,
        additionalParameter: { type: 'inventFund' },
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
        allowedMimeType: this.allowedType,
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg"],
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "pdf" ,"doc" ]
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"],
        maxFileSize: 50 * 1024 * 1024,
      });
      // this.uUploader.options.allowedMimeType = this.allowedType;
      // console.log('File Type', this.fileType);
      // console.log('File Type', this.allowedType);

      this.uploadTitle = "Upload File";
    } else if (fileType == 'img') {
      this.allowedType = ['image/jpg', 'image/png', 'image/jpeg'];
      // this.uUploader.options.allowedMimeType = this.allowedType;
      this.uUploader = new FileUploader({
        url: URL,
        additionalParameter: { type: 'inventFund' },
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
        allowedMimeType: this.allowedType,
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg"],
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "pdf" ,"doc" ]
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"],
        maxFileSize: 50 * 1024 * 1024,
      });
      // console.log('File Type', this.fileType);
      // console.log('File Type', this.allowedType);

      this.uploadTitle = "Upload Image";
    } else if (fileType == 'video') {
      // this.allowedType = ["video/mp4", "video/quicktime"];
      this.allowedType = [
        'video/mp4',
        'video/quicktime',
        'video/webm',
        'video/ogg',
        'video/x-ms-wmv',
        'video/x-flv',
        'video/3gpp',
        'video/3gpp2',
        'video/x-matroska',
        'video/x-msvideo',
        'video/x-ms-asf',
      ];

      // this.uUploader.options.allowedMimeType = this.allowedType;
      this.uUploader = new FileUploader({
        url: URL,
        additionalParameter: { type: 'inventFund' },
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
        allowedMimeType: this.allowedType,
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg"],
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "pdf" ,"doc" ]
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"],
        maxFileSize: 50 * 1024 * 1024,
      });
      // console.log('File Type', this.fileType);
      // console.log('File Type', this.allowedType);

      this.uploadTitle = "Upload Video";
    }else  if (fileType == 'pdfWord') {
      this.allowedType = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      this.uUploader = new FileUploader({
        url: URL,
        additionalParameter: { type: 'inventFund' },
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
        allowedMimeType: this.allowedType,
         maxFileSize: 50 * 1024 * 1024,
      });
      // this.uUploader.options.allowedMimeType = this.allowedType;
      // console.log('File Type', this.fileType);
      // console.log('File Type', this.allowedType);

      this.uploadTitle = "Upload File";
    }else  if (fileType == 'pdf') {
      this.allowedType = [
        'application/pdf',
        // 'application/msword',
        // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ];
      this.uUploader = new FileUploader({
        url: URL,
        additionalParameter: { type: 'inventFund' },
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
        allowedMimeType: this.allowedType,
         maxFileSize: 50 * 1024 * 1024,
      });
      // this.uUploader.options.allowedMimeType = this.allowedType;
      // console.log('File Type', this.fileType);
      // console.log('File Type', this.allowedType);

      this.uploadTitle = "Upload File";
    }
     else {
      this.allowedType = ['image/jpg', 'image/png', 'image/jpeg'];
      // this.uUploader.options.allowedMimeType = this.allowedType;
      this.uUploader = new FileUploader({
        url: URL,
        additionalParameter: { type: 'inventFund' },
        headers: [
          {
            name: 'Accept',
            value: 'application/json',
          },
        ],
        allowedMimeType: this.allowedType,
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg"],
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "pdf" ,"doc" ]
        // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"],
        maxFileSize: 50 * 1024 * 1024,
      });
      // console.log('File Type', this.fileType);
      // console.log('File Type', this.allowedType);
      this.uploadTitle = "Upload Image";
    }
    // console.log('File TYPE=========== ', fileType);
  }

  // fileupload required data
  //  allowedType = ["image/jpg", "image/png", "image/jpeg"];

  // ==================================== Old Code Starts ==============================================
  // public uUploader: FileUploader = new FileUploader({
  //   url: URL,
  //   additionalParameter: { type: 'inventFund' },
  //   headers: [
  //     {
  //       name: 'Accept',
  //       value: 'application/json',
  //     },
  //   ],
  //   allowedMimeType: this.allowedType,
  //   // allowedMimeType: ["image/jpg", "image/png", "image/jpeg"],
  //   // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "pdf" ,"doc" ]
  //   // allowedMimeType: ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"],
  //   maxFileSize: 50 * 1024 * 1024,
  // });
  // ==================================== Old Code Ends ==============================================

  // public uUploader: FileUploader;

  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  /**
   *
   * @param appState get variables and methods from app state
   */
  constructor(public appState: AppStateService) {
    // console.log('each image local : ' + this.appState.profileImageSigned);
    // this.setAvatar();
    // if (sessionStorage.getItem('profileImage')) {
    //   this.image = "file_upload_label_img";
    //   this.imageDataAssign = sessionStorage.getItem('profileImage');

    // }

    //  if(this.fileType == 'doc'){
    //   this.allowedType = ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"];
      // console.log("If Section");
    //  }else if (this.fileType == 'image'){
    //   this.allowedType = ["image/jpg", "image/png", "image/jpeg"];
      // console.log("Else If Section");
    //  } else {
    //   this.allowedType = ["image/jpg", "image/png", "image/jpeg"];
      // console.log("Else Section");
    //  }

    // ======================================= New Code ===================================

    // @Input() fileType: string;

    //     @Input()
    //  set fileType(fileType: any) {
    //       if(fileType == 'doc') {
    //         allowedType = []
    //       } else {
    //         allowedType = []
    //       }

    // @Input()
    // set fileType(this.fileType) {
    //   if (this.fileType === 'doc') {
    //     this.allowedType = ['pdf'];
    //   } else {
    //     this.allowedType = ['img'];
    //   }
    // }

    // console.log('File Type in Constructor After', this.fileType);
    // console.log('Allowed Type in Constructor After', this.allowedType);

    //   if (this.fileType == 'doc') {
    //     // this.allowedType = ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"];
    //     this.allowedType = ["application/pdf",
    //     "application/vnd.ms-excel",
    //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     "application/msword",
    //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //     "application/vnd.oasis.opendocument.text",
    //     "application/vnd.oasis.opendocument.spreadsheet",
    //     "image/gif",
    //     "image/jpeg",
    //     "image/png"
    //   ];
      // console.log("If Section");
    // } else {
    //   this.allowedType = ["image/jpg", "image/png", "image/jpeg"];
      // console.log("Else Section");
    // }
    // console.log("File Type in Constructor After", this.fileType);
  }

  formatBytes(bytes, decimals?) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024,
      dm = decimals || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // ngOninit

  ngOnInit() {
    // console.log('File Type in On Init', this.fileType);
    // set folder path for an attachment URL

    this.uUploader.onProgressItem = (progress: any) => {
      this.showProgress = true;
      // console.log('................' + progress['progress']);
    };

    this.uUploader.onWhenAddingFileFailed = (
      item: any,
      filter: any,
      options: any
    ) => {
      const json = JSON.stringify(filter);
      // console.log('local after : ' + JSON.stringify(item));
      // console.log('filter after : ' + JSON.stringify(filter));
      // console.log('Filter Name : ' + JSON.stringify(filter.name));
      this.showProgress = false;
      const name = item.name;
      const lastDot = name.lastIndexOf('.');

      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      // console.log('ext :' + ext);
      let message = '';

      switch (filter.name) {
        case 'fileSize':
          message =
            'Warning ! \nThe uploaded file "' +
            item.name +
            '" is ' +
            this.formatBytes(item.size) +
            ', this exceeds the maximum allowed size of ' +
            this.formatBytes(50 * 1024 * 1024);
          Swal.fire({
            title: message,
            // text: message,
            // type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Okay!',
          });
          break;

        // case 'mimeType': message = "." + ext + " File not allowed! Only images are allowed";
        case 'mimeType':
          message = '.' + ext + ' Files are not allowed!';
          Swal.fire({
            title: message,
            // text: message,
            // type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Okay!',
          });
          break;
        default:
          message = 'Error trying to upload file   ' + item.name;

          Swal.fire({
            title: message,
            // text: message,
            // type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Okay!',
          });
          break;
      }
    };

    this.uUploader.onBuildItemForm = (item, form) => {
      // console.log("append type : ")
      form.append('TYPE', this.uImagePathParam.type);
    };

    // get response oncomplete of attachment

    this.uUploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      // console.log('response data : ' + JSON.stringify(response));
      // console.log('????' + this.formatBytes(50 * 1024 * 1024));

      var data: any = response;
      var image = JSON.parse(data).signedData;
      var emitImage = JSON.parse(data).data;
      this.image = 'file_upload_label_img';
      this.appState.setAvatar(image);
      // this.imageDataAssign = this.appState.getAvatar();
      this.imageUrl.emit(emitImage);
      this.showProgress = false;
    };
  }

  // on file select complete upload in to s3 bucket

  // onFileSelected(event) {
  //   if(this.fileType === 'doc'){
  //     this.allowedType = ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"];
      // console.log("If Section");
  //    }else if (this.fileType === 'image'){
  //     this.allowedType = ["image/jpg", "image/png", "image/jpeg"];
      // console.log("Else If Section");
  //    } else {
  //     this.allowedType = ["image/jpg", "image/png", "image/jpeg"];
      // console.log("Else Section");
  //    }
  //   const json = JSON.stringify(event);
    // console.log("original image : " + json)
  //   this.uUploader.uploadAll();
  // }

  onFileSelected(event) {
    // this.uUploader.options.allowedMimeType = this.allowedType;

    // if (this.fileType == 'doc') {
    //   // this.allowedType = ["image/jpg", "image/png", "image/jpeg", "application/pdf", "application/msword", "application/vnd.ms-excel"];
    //   this.allowedType = ["application/pdf",
    //     "application/vnd.ms-excel",
    //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     "application/msword",
    //     "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //     "application/vnd.oasis.opendocument.text",
    //     "application/vnd.oasis.opendocument.spreadsheet",
    //     "image/gif",
    //     "image/jpeg",
    //     "image/png"
    //   ];
    //   this.uUploader.options.allowedMimeType = this.allowedType;
    //   console.warn("If Section");
    //   console.warn("Allowed Type in if block", this.allowedType);
    // } else {
    //   this.allowedType = ["image/jpg", "image/png", "image/jpeg"];
    //   this.uUploader.options.allowedMimeType = this.allowedType;
    //   console.warn("Else Section");
    //   console.warn("Allowed Type in else block", this.allowedType);
    // }

    console.log("Allowed Type", this.allowedType);

    const json = JSON.stringify(event);
    // console.log('original image : ' + json);
    this.uUploader.uploadAll();

    // console.log('File Type in onFileSelected', this.fileType);
    // console.log('Allowed Type ', this.allowedType);
    // this.fileType = "";
  }

  // setAvatar() {
  //   this.appState.getProfileImage();
  //   // this.appState.getProfileImage('single-attachment');
  //   console.log('image data with id : ' + this.appState.getProfileImage('single-attachment'));
  //   if (sessionStorage.getItem('profileImage')) {
  //     this.appState.setAvatar(sessionStorage.getItem('profileImage'));
  //   } else {
  //     this.appState.setAvatar('assets/profile-placeholder.png');
  //   }
  //   this.image = 'file_upload_label_img';
  //   this.imageDataAssign = this.appState.getAvatar();
  //   // console.log('image : ' + JSON.stringify(this.appState.avatar$.getValue()));
  // }

  clickFileInput() {
    document.getElementById('fileInput').click();
  }

  // triggerFileInput() {
  //   const fileInput = document.getElementById('upload') as HTMLElement;
  //   fileInput.click();
  // }
}
