import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { PusherService } from '../messages/services/pusher.service';
import { CommonServiveService } from 'src/app/common-servive.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { InventfundsApiService } from 'src/app/services/inventfunds-api.service';
import { toastMessages } from 'src/utilities/toastMessage';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded';
import EmbeddedClient from '@zoom/meetingsdk/embedded';
import { DOCUMENT } from '@angular/common';
import { ZoomMtg } from '@zoom/meetingsdk';


@Component({
  selector: 'app-video-caller',
  templateUrl: './video-caller.component.html',
  styleUrls: ['./video-caller.component.scss'],
})
export class VideoCallerComponent implements OnInit {
  @ViewChild('videoCallerDialog') videoCallerDialog: DialogModule;
  @ViewChild('audioRef', { static: false }) audioRef: ElementRef<HTMLAudioElement>;

  @Input() videoCallScreenType: string = ''; // This input will determine which UI to show
  @Input() callData: any;
  @Input() roleType: any;
  @Input() receiverPersonChannelId: any;
  @Input() senderPersonChannelId: any;

  visible: boolean = false;
  sdkKey = '3RZ1iLgGRGphjW3AKq0Ow';
  userName = '';
  userEmail = '';

  // client = ZoomMtgEmbedded.createClient();
  // TODO: Refine type later (currently using 'any' for convenience)
  client: any;
  registrantToken: string;
  zakToken: string;
  connecting: boolean = false;

  constructor(
    private pusherService: PusherService,
    public CommonService: CommonServiveService,
    public appStateService: AppStateService,
    private inventFundsApiService: InventfundsApiService,
    private ngZone: NgZone,
    private router: Router,
    public navCtrl: NavController,
  ) { }

  ngOnInit() {
    console.log("this.callData", this.callData);

    this.userName = this.appStateService.globalData.name;
    this.userEmail = this.appStateService.globalData.personEmail;

  }

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Toggle Caller Dialog Starts
 ** -------------------------------------------------------------------------------------------*/
  toggleCallerDialogReceiver() {
    console.log("this.callData", this.callData);
    // this.videoCallerDialog.toggle(event);
    this.visible = !this.visible;
    console.log("this.visible:", this.visible);
    console.log("this.videoCallScreenType:", this.videoCallScreenType);

    // Play the ringtone
    this.playRingtone();

  };

  toggleCallerDialogCaller() {
    console.log("this.callData", this.callData);
    // this.videoCallerDialog.toggle(event);
    this.visible = !this.visible;
    console.log("this.visible:", this.visible);
    console.log("this.videoCallScreenType:", this.videoCallScreenType);

  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to End Call by Caller Starts
 ** -------------------------------------------------------------------------------------------*/
  endCallByCaller() {
    this.visible = !this.visible;
    console.log("this.visible:", this.visible);

    this.stopRingtone();
    this.videoCallScreenType = '';
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Decline Call by Receiver Starts
** -------------------------------------------------------------------------------------------*/
  declineCallByReceiver() {
    console.log("declineCallByReceiver called");
    this.videoCallScreenType = '';
    this.visible = !this.visible;
    console.log("this.visible:", this.visible);

    this.stopRingtone();
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Accept Call by Receiver Starts
** -------------------------------------------------------------------------------------------*/
  acceptCallByReceiver() {
    console.log("acceptCallByReceiver called");
    // this.videoCallScreenType = '';
    this.visible = !this.visible;
    console.log("this.visible:", this.visible);
  };

  /* ---------------------------------------------------------------------------------------------------
   ** Method to Play Ringtone Starts
   ** -------------------------------------------------------------------------------------------*/
  playRingtone() {
    console.log('playRingtone called');
    this.stopRingtone(); // Ensure any previous ringtone is stopped

    if (this.audioRef && this.audioRef.nativeElement) {
      const audioElement = this.audioRef.nativeElement;

      // Play the audio in response to a user action
      audioElement.play().catch(error => {
        console.error('Failed to play audio:', error);
      });

      // Add an event listener to loop the audio when it ends
      audioElement.onended = () => {
        console.log('Ringtone ended, restarting...');
        audioElement.currentTime = 0;
        audioElement.play().catch(error => {
          console.error('Failed to loop audio:', error);
        });
      };
    }
  };


  /* ---------------------------------------------------------------------------------------------------
  ** Method to Stop Ringtone Starts
  ** -------------------------------------------------------------------------------------------*/
  stopRingtone() {
    console.log("stopRingtone called")

    if (this.audioRef && this.audioRef.nativeElement) {
      const audioElement = this.audioRef.nativeElement;
      audioElement.pause();
      audioElement.currentTime = 0;
      audioElement.onended = null; // Remove the loop event listener
    }
  }


  /* ---------------------------------------------------------------------------------------------------
 ** Method to Notify Receiver Video Call has ended by Sender Starts
 ** -------------------------------------------------------------------------------------------*/
  notifyReceiverVideoCallEnd() {
    const eventName = 'reply-notification';

    const data = {
      notificationType: "videoCallEnd",
      timestamp: new Date(),
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.receiverPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Notify Sender Video Call Declined by Receiver Starts
 ** -------------------------------------------------------------------------------------------*/
  notifySenderVideoCallDeclined() {
    console.log("this.senderPersonChannelId", this.senderPersonChannelId);

    const eventName = 'reply-notification';

    const data = {
      notificationType: "videoCallDeclined",
      timestamp: new Date(),
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.senderPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
 ** Method to Notify Sender Video Call Accepted by Receiver Starts
 ** -------------------------------------------------------------------------------------------*/
  notifySenderVideoCallAccepted() {
    console.log("this.senderPersonChannelId", this.senderPersonChannelId);

    const eventName = 'reply-notification';

    const data = {
      notificationType: "videoCallAccepted",
      timestamp: new Date(),
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.senderPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Start a Video Call
** -------------------------------------------------------------------------------------------*/
  // startVideoCall() {
  //   var params = {}; // Assuming there are no parameters needed for creating a meeting
  //   this.connecting = true;

  //   this.inventFundsApiService
  //     .createMeeting(params)
  //     .then((meetingData) => { // Now meetingData will be the entire response object

  //       console.log("Meeting Data", meetingData);
  //       console.log("MeetingData.id", meetingData.id);
  //       console.log("MeetingData.password", meetingData.password);

  //       this.connecting = false;
  //       this.endCallByCaller();

  //       if (meetingData?.id && meetingData?.password) {
  //         // If meeting is successfully created, call getSignature with the meeting ID and password
  //         this.getSignatureCaller(meetingData.id, meetingData.password);
  //       } else {
  //         console.log('Error creating meeting:', meetingData);
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.error("Error in startVideoCall:", err);
  //       var obj = toastMessages.retrieveErrMess;
  //       this.appStateService.genericToast(obj);
  //     });
  // }

  startVideoCall() {
    var params = {};
    this.connecting = true;

    this.inventFundsApiService
      .createMeeting(params)
      .then((meetingData) => {
        console.log("Meeting Data", meetingData);
        console.log("MeetingData.id", meetingData.id);
        console.log("MeetingData.password", meetingData.password);

        this.connecting = false;
        this.endCallByCaller();

        if (meetingData?.id && meetingData?.password) {
          this.getSignatureCaller(meetingData.id, meetingData.password);
        } else {
          console.log('Error creating meeting:', meetingData);
        }
      })
      .catch((err) => {
        console.error("Error in startVideoCall:", err);

        if(err){
          this.notifyReceiverMeetingFailedToConnect();
        }
      });
  }


  /* ---------------------------------------------------------------------------------------------------
  ** Method to Generate Zoom SDK Signature for Caller
  ** -------------------------------------------------------------------------------------------*/
  // getSignatureCaller(meetingNumber: string, passcode: string) {
  //   var params = {
  //     meetingNumber: meetingNumber,
  //     role: 1,
  //   };

  //   this.inventFundsApiService
  //     .generateSignature(params)
  //     .then((res) => {
  //       // let data = res.response;
  //       let signature = res.signature; // Make sure to access the correct key

  //       console.log("Generated Signature Data", signature);

  //       if (signature) {
  //         // If signature is successfully generated, call startMeeting with the signature, meeting number, and passcode

  //         this.startMeetingCaller(signature, meetingNumber, passcode);
  //         // this.notifyReceiverMeetingDetails(meetingNumber, passcode, signature);
  //       } else {
  //         console.log('Error generating signature:', signature);
  //       }
  //     })
  //     .catch((err: any) => {
  //       console.error('Error generating signature:', err);
  //       var obj = toastMessages.retrieveErrMess;
  //       this.appStateService.genericToast(obj);
  //     });
  // };

  getSignatureCaller(meetingNumber, passcode) {
    var params = {
      meetingNumber: parseInt(meetingNumber), // Ensure meetingNumber is an integer
      role: 1,
    };

    this.inventFundsApiService
      .generateSignature(params)
      .then((res) => {
        let signature = res.signature;
        console.log("Generated Signature", signature);

        if (signature) {
          this.startMeetingCaller(signature, meetingNumber, passcode);
        } else {
          console.log('Error generating signature:', signature);
        }
      })
      .catch((err) => {
        console.error('Error generating signature:', err);

        if(err){
          this.notifyReceiverMeetingFailedToConnect();
        }
      });
  }

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Start Zoom Meeting for Caller
  ** -------------------------------------------------------------------------------------------*/
  startMeetingCaller(signature: string, meetingNumber: string, passcode: string) {

    // let meetingSDKElement = document.getElementById('meetingSDKElement');

    // Initializing the div to Display the Zoom Meeting in Component View
    const meetingSDKElement = document.createElement('div');
    meetingSDKElement.id = 'meetingSDKElement';
    document.body.appendChild(meetingSDKElement);

    // Creating the Client
    this.client = ZoomMtgEmbedded.createClient();

    this.ngZone.runOutsideAngular(() => {
      this.client.init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        patchJsMedia: true,
        leaveOnPageUnload: true,
        debug: true,

        // For Customizing the Zoom Component View UI
        customize: {

          video: {
            isResizable: false,
            // defaultViewType: "ribbon" as SuspensionViewType,
            viewSizes: {
              default: {
                width: 300,
                height: 500
              },
              ribbon: {
                width: 300,
                height: 700
              }
            },
            popper: {
              disableDraggable: true
            },

          },

          meetingInfo: ['topic', 'host', 'participant'],
          toolbar: {
            // buttons: [
            //   {
            //     text: 'End Call',
            //     className: 'CustomButton',
            //     onClick: () => {
            //       console.log('custom button');
            //       this.client.endMeeting().then(() => {
            //         console.log('Meeting ended, destroying client...');
            //         ZoomMtgEmbedded.destroyClient();
            //       }).catch((error) => {
            //         console.error('Error ending meeting:', error);
            //       });
            //     }

            //   }
            // ]
          }
        }

      }).then(() => {
        this.client.join({
          signature: signature,
          sdkKey: this.sdkKey,
          meetingNumber: meetingNumber,
          password: passcode,
          userName: this.userName,
          userEmail: this.userEmail,
          // tk: this.registrantToken,
          // zak: this.zakToken
        }).then(() => {
          console.log('joined successfully');
          this.notifyReceiverMeetingDetails(meetingNumber, passcode, signature);

          // Listen to meeting events
          this.client.on("connection-change", (payload) => {
            if (payload.state === 'Closed') {
              console.log("Meeting ended");
              try {
                ZoomMtgEmbedded.destroyClient();
                console.log("Client destroyed successfully");
                this.client.off('connection-change');
                setTimeout(() => {
                  document.getElementById('meetingSDKElement').remove();
                }, 2000); // Slight delay before removing the div so that the meeting ended Message can be displayed
              } catch (error) {
                console.error("Error destroying client:", error);
              }
            }
          });

        }).catch((error) => {
          console.log(error);

          if(error){
            console.log("Failed to connect from the Caller's Side");
            this.notifyReceiverMeetingFailedToConnect();
          }
        })
      }).catch((error) => {
        console.log(error);
        if(error){
          console.log("Failed to connect from the Caller's Side");
          this.notifyReceiverMeetingFailedToConnect();
        }
      })
    })
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Notify Sender Video Call Accepted by Receiver Starts
** -------------------------------------------------------------------------------------------*/
  notifyReceiverMeetingDetails(meetingNumber, passcode, signature) {
    const eventName = 'reply-notification';

    const data = {
      notificationType: "meetingDetails",
      timestamp: new Date(),
      meetingDetails: {
        signature: signature,
        meetingNumber: meetingNumber,
        passcode: passcode
      }
    };

    // Construct the channel name dynamically
    const channelName = `private-crecientech-chat${this.receiverPersonChannelId}`;

    console.log('Sending chat notification to channel:', channelName);
    console.log('Notification data:', data);

    // Notify the receiver (ensure channelId matches the receiver's subscription)
    this.pusherService.notifyUser(channelName, eventName, data);
  };

  /* ---------------------------------------------------------------------------------------------------
  ** Method to Generate Zoom SDK Signature for Receiver
  ** -------------------------------------------------------------------------------------------*/
  getSignatureReceiver(meetingDetails) {
    console.log("meetingDetails", meetingDetails);
    console.log("meetingDetails?.meetingNumber", meetingDetails?.meetingNumber);
    this.connecting = true;
    this.stopRingtone;

    var params = {
      meetingNumber: meetingDetails.meetingNumber,
      role: 0,
    };

    this.inventFundsApiService
      .generateSignature(params)
      .then((res) => {
        // let data = res.response;
        let signature = res.signature; // Make sure to access the correct key

        console.log("Generated Signature Data", signature);

        if (signature) {
          // If signature is successfully generated, call startMeeting with the signature, meeting number, and passcode
          this.joinMeetingReceiver(meetingDetails, signature);
          this.connecting = false;
          this.acceptCallByReceiver();
        } else {
          console.log('Error generating signature:', signature);
        }
      })
      .catch((err: any) => {
        console.error('Error generating signature:', err);
        var obj = toastMessages.retrieveErrMess;
        this.appStateService.genericToast(obj);
      });
  };


  /* ---------------------------------------------------------------------------------------------------
  ** Method to Start Zoom Meeting for Receiver
  ** -------------------------------------------------------------------------------------------*/
  joinMeetingReceiver(meetingDetails, signature) {
    console.log("meetingDetails", meetingDetails);
    console.log("meetingDetails?.meetingNumber", meetingDetails?.meetingNumber);
    console.log("signature", signature);


    // let meetingSDKElement = document.getElementById('meetingSDKElement');

    // Initializing the div to Display the Zoom Meeting in Component View
    const meetingSDKElement = document.createElement('div');
    meetingSDKElement.id = 'meetingSDKElement';
    document.body.appendChild(meetingSDKElement);

    // Creating the Client
    this.client = ZoomMtgEmbedded.createClient();

    this.ngZone.runOutsideAngular(() => {
      this.client.init({
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        patchJsMedia: true,
        leaveOnPageUnload: true,
        debug: true,

        // For Customizing the Zoom Component View UI
        customize: {

          video: {
            isResizable: false,
            // defaultViewType: "ribbon" as SuspensionViewType,
            viewSizes: {
              default: {
                width: 300,
                height: 500
              },
              ribbon: {
                width: 300,
                height: 700
              }
            },
            popper: {
              disableDraggable: true
            },

          },

          meetingInfo: ['topic', 'host', 'participant'],
          toolbar: {
            // buttons: [
            //   {
            //     text: 'Custom Button',
            //     className: 'CustomButton',
            //     onClick: () => {
            //       console.log('custom button');
            //     }
            //   }
            // ]
          }
        }

      }).then(() => {
        this.client.join({
          signature: signature,
          sdkKey: this.sdkKey,
          meetingNumber: meetingDetails.meetingNumber,
          password: meetingDetails.passcode,
          userName: this.userName,
          userEmail: this.userEmail,
          // tk: this.registrantToken,
          // zak: this.zakToken
        }).then(() => {
          console.log('joined successfully');

          // Listen to meeting events
          this.client.on("connection-change", (payload) => {
            if (payload.state === 'Closed') {
              console.log("Meeting ended");
              try {
                ZoomMtgEmbedded.destroyClient();
                console.log("Client destroyed successfully");
                this.client.off('connection-change');
                setTimeout(() => {
                  document.getElementById('meetingSDKElement').remove();
                }, 2000); // Slight delay before removing the div so that the meeting ended Message can be displayed
              } catch (error) {
                console.error("Error destroying client:", error);
              }
            }
          });

        }).catch((error) => {
          console.log(error)
        })
      }).catch((error) => {
        console.log(error)
      })
    })
  };



  showConnecting() {
    this.connecting = true;
    this.stopRingtone();
  };

  /* ---------------------------------------------------------------------------------------------------
** Method to Notify Receiver the Meeting Failed to Connect Starts
** -------------------------------------------------------------------------------------------*/
notifyReceiverMeetingFailedToConnect() {
  const eventName = 'reply-notification';

  const data = {
    notificationType: "meetingFailedToConnect",
    timestamp: new Date(),
  };

  // Construct the channel name dynamically
  const channelName = `private-crecientech-chat${this.receiverPersonChannelId}`;

  console.log('Sending chat notification to channel:', channelName);
  console.log('Notification data:', data);

  // Notify the receiver (ensure channelId matches the receiver's subscription)
  this.pusherService.notifyUser(channelName, eventName, data);
};


}
