import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { destroyView } from '@ionic/angular/directives/navigation/stack-utils';
import * as moment from 'moment';
import { InventfundsApiService } from 'src/app/services/inventfunds-api.service';
@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements OnInit {
  a: any;
  varString: string = "hellowor;ld"
  b: any;
  date1: any;
  label: any;
  content: any;
  c: any;
  result: any;
  commentBy: any;
  aeiou: any;
  date: any;
  // @Input() rating;
  rating: number = 3;


  constructor(private inventFundsApiService: InventfundsApiService,) { }

  ngOnInit() {
    this.getReviews()

  }


  getReviews() {
    var params = {
      collectionName: 'review',
    };
    this.inventFundsApiService.retrieveMongoDBAll(params).then(res => {

      this.a = res.response.date
      console.log(res.response)
      this.result = res.response
      this.b = res.response.date
      this.c = parseInt(res.response.rating)
      this.label = res.response.label
      this.content = res.response.content
      this.commentBy = res.response.commentBy
      this.date = res.response.xyz
      //Date 1 from db
      var date1 = res.response[0].date;

      var date2 = new Date();

      let aeiou = res.response[0].date


      let dateString1 = new Date();
      let momentVariable1 = moment(dateString1, 'DD-MM-YYYY');
      let stringvalue1 = momentVariable1.format('MM/DD/YYYY')


      var st = aeiou;
      var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
      var dt = new Date(st.replace(pattern, '$3-$2-$1'));
      const date4 = new Date(stringvalue1);


      console.log("**************************** D", getDifferenceInDays(date4, dt));
      console.log("****************************M", getDifferenceInMinutes(date4, dt));
      console.log("****************************H", getDifferenceInHours(date4, dt));
      console.log("****************************S", getDifferenceInSeconds(date4, dt));
      console.log("****************************Y", getDifferenceInYears(date4, dt));


      var days = getDifferenceInDays(date4, dt)
      var hours = getDifferenceInHours(date4, dt)
      var mins = getDifferenceInMinutes(date4, dt)
      var sec = getDifferenceInSeconds(date4, dt)
      var years = getDifferenceInYears(date4, dt)
      // var a : any


      if (sec < 60) {
        console.log("Just now----------->>>>>>>>>>>>", sec)
        //this.a =sec
        this.a = "Just now"

      }
      else if (mins < 60) {
        console.log(mins + " mins ago------------------->>>>>>>>>>>")
        this.a = mins + " " + "mins ago"
      }
      else if (hours < 12) {
        console.log(hours + "hours ago------------------->>>>>>>>>>>")
        this.a = hours + " " + "hours ago"


      }
      else if (days < 30) {
        console.log(days + "days ago------------------->>>>>>>>>>>")
        this.a = days + " " + "days ago"


      }
      else if (years > 0) {
        console.log(years + "year ago --------------------->>>>>>")
        this.a = years + " " + "years ago"

      }






    })
  }
}



// ==================================================================



function getDifferenceInSeconds(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / 1000;
}



function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  const diff = diffInMs / (1000 * 60);
  return Math.floor(diff)

}

function getDifferenceInHours(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  const diff = diffInMs / (1000 * 60 * 60);
  return Math.floor(diff)

}
function getDifferenceInDays(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  const diff = diffInMs / (1000 * 60 * 60 * 24);
  return Math.floor(diff)

}
function getDifferenceInYears(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  const diff = diffInMs / (365 * 1000 * 60 * 60 * 24);
  return Math.ceil(diff)


}


// function logRatingChange(e){
//    var el=document.querySelector<HTMLElement>("button-inner")

//   //  for(var i=0; i<el.length; i++) {
//     el.style.visibility = 'hidden';
//   //}


//   const test = Array.from(document.getElementsByClassName('button-inner'));
//   test.forEach((element) => {
//       element.innerHTML = '<div class="good-day-today" style="width: 0px;"></div>'; // Please note that this line works fine!
//       element.style.padding = '10px';
//       element.style.borderTop = '0';
//   });



//   // document.getElementById("span").className="button-inner";
//   // var divsToHide= document.getElementsByClassName("button-inner")
//   // divsToHide.style.display="none";

// //   for(var i = 0; i < divsToHide.length; i++){
//     divsToHide[i].style.visibility = "hidden"; // or
//     divsToHide[i].style.display = "none"; // depending on what you're d



