export const toastMessages = {
  alreadyRegMess: {
    message: "You are already registered with us! Please Login",
    color: "warning",
    position: 'bottom',
  },
  timeErrorMess: {
    message: "Next time should be greater than previous time.",
    color: "danger",
    position: 'bottom',
  },
  invalidEmailError: {
    message: "Please login with registered email id.",
    color: "danger",
    position: 'bottom',
  },
  customSignUpError: {
    message: "You have registered using Custom Sign Up.Please use the login form to login",
    color: "danger",
    position: 'bottom',
  },
  emailNotActivatedError: {
    message: "Email id not activated",
    color: "danger",
    position: 'bottom',
  },
  signInError: {
    message: "There was an issue signing in with your account. Please try again later",
    color: "danger",
    position: 'bottom',
  },
  updatePwsSuccess: {
    color: "success",
    message: "Updated password successfully , login with new password to continue!",
    position: 'bottom'
  },
  setPwdWarning: {
    message: "Can't set new password for social login !",
    color: "warning",
    position: 'bottom'
  },
  skillWarningMess: {
    message: "Atleast one skill is required!",
    color: "warning",
    position: 'bottom'
  },
  resendOtpMess: {
    message: "OTP has been sent to your mobile number!",
    color: "success",
    position: 'bottom'
  },
  invalidOtpMess: {
    message: "Invalid OTP entered !!",
    color: "danger",
    position: 'bottom',
  },
  accDetailsUpdateMess: {
    message: "Updated  Account Details Successfully",
    color: "success",
    position: 'bottom',
  },
  generalInfoDetailsUpdateMess: {
    message: "Updated General Info Details Successfully",
    color: "success",
    position: 'bottom',
  },
  certificateDetailsUpdateMess: {
    message: "Updated Certificate Details Successfully",
    color: "success",
    position: 'bottom',
  },
  feedBackSentMess: {
    message: "Feedback submitted",
    color: "success",
    position: 'bottom',
  },
  experienceDetailsUpdateMess: {
    message: "Updated Experience Details Successfully",
    color: "success",
    position: 'bottom',
  },
  updatePwdSucessMess: {
    message: "Updated password successfully",
    color: "success",
    position: 'bottom',
  },
  privacyPolicyAccMess: {
    message: "Privacy policy accepted successfully",
    color: "success",
    position: 'bottom',
  },
  privacyPolicyRejMess: {
    message: "Privacy policy rejected!",
    color: "danger",
    position: 'bottom',
  },
  termsandConAccMess: {
    message: "Terms and conditions accepted successfully",
    color: "success",
    position: 'bottom',
  },
  termsandConyRejMess: {
    message: "Terms and conditions rejected!",
    color: "danger",
    position: 'bottom',
  },
  retrieveErrMess: {
    message: "Something went wrong! Try again later.",
    color: "danger",
    position: 'bottom',
  },
  updateErrMess: {
    message: "couldn't update data! Try again later.",
    color: "danger",
    position: 'bottom',
  },
  deleteErrMess: {
    message: "couldn't Delete data! Try again later.",
    color: "danger",
    position: 'bottom',
  },
  invalidFormErrMess: {
    message: "Try filling the mandatory fields.",
    color: "danger",
    position: 'bottom',
  },
  noDayAdded: {
    message: "Please enter the day",
    color: "danger",
    position: 'bottom',
  },
  noTimeAdded: {
    message: "Please enter the time",
    color: "danger",
    position: 'bottom',
  },
  saveErrMsg: {
    message: "couldn't save the data! Try again later.",
    color: "danger",
    position: 'bottom',
  },
  ndaUploadErrMsg: {
    message: "Cannot upload more than one NDA.",
    color: "danger",
    position: 'bottom',
  },
  signInActiveErrMsg: {
    message: "This account is deactivated.",
    color: "danger",
    position: 'bottom',
  },
  noVideoFound: {
    message: "No Video Found, Upload a Video to Play.",
    color: "warning",
  },
  myNdaMsg: {
    message: "Visit Dashboard to use the NDA Widget",
    color: "dark",
    position: 'bottom',
  },
  comingSoonMsg: {
    message: "Screen Coming Soon...",
    color: "dark",
    position: 'bottom',
  },
  functionalityComingSoon: {
    message: "Functionality Coming Soon...",
    color: "dark",
    position: 'bottom',
  },
  comingSoonDefaultMsg: {
    message: "Coming Soon...",
    color: "dark",
    position: 'bottom',
  },
  imageDeletedMsg: {
    message: "Profile Image Deleted Successfully",
    color: "light",
    position: 'bottom',
  },
  videoDeletedMsg: {
    message: "Profile Video Deleted Successfully",
    color: "light",
    position: 'bottom',
  },
  ideaVideoDeletedMsg: {
    message: "Video Deleted Successfully",
    color: "light",
    position: 'bottom',
  },
  emailSentMsg: {
    message: "Invite Email sent to Recipient Email IDs",
    color: "success",
    position: 'bottom',
  },
  inviteEmailSentMsg: {
    message: "Invite Email has been sent to Team Member's Email Id",
    color: "success",
    position: 'bottom',
  },
  inviteEmailSendErrorMsg: {
    message: "Invite Email could not be sent",
    color: "Danger",
    position: 'bottom',
  },
  ideaCreatedMsg: {
    message: "Your New Idea Has be Saved",
    color: "success",
    position: 'bottom',
  },
  ideaUpdatedMsg: {
    message: "Idea Updated Successfully",
    color: "success",
    position: 'bottom',
  },
  ideaDeletedMsg: {
    message: "Idea Deleted Successfully",
    color: "success",
    position: 'bottom',
  },
  ideaArchivedMsg: {
    message: "Idea Archived Successfully",
    color: "success",
    position: 'bottom',
  },
  ideaRestoredMsg: {
    message: "Idea Restored Successfully",
    color: "success",
    position: 'bottom',
  },
  pdfErrMess: {
    message: "Please enter the details to download",
    color: "danger",
    position: 'bottom',
  },
  noProfileVideoFound: {
    message: "No video found <br>The user has not uploaded their profile video",
    color: "warning",
  },
  matchExpPdfErrMess: {
    message: "No data in experince to download as pdf",
    color: "danger",
    position: 'bottom',
  },
  matchExpPngErrMess: {
    message: "No data in experince to download as png",
    color: "danger",
    position: 'bottom',
  },
  matchGenPdfErrMess: {
    message: "No data in general information to download as pdf",
    color: "danger",
    position: 'bottom',
  },
  matchGenPngErrMess: {
    message: "No data in general information to download as png",
    color: "danger",
    position: 'bottom',
  },
  projectCreatedMess: {
    message: "Project Created Successfully",
    color: "success",
    position: 'bottom',
  },
  projectUpdatedMess: {
    message: "Project Updated Successfully",
    color: "success",
    position: 'bottom',
  },
  LogoImageDeletedMsg: {
    message: "Project Logo Image Deleted Successfully",
    color: "success",
    position: 'bottom',
  },
  TeamMemberDeletedMsg: {
    message: "TeamMember Deleted Successfully",
    color: "success",
    position: 'bottom'
  },
  taskDeletedMsg: {
    message: "Task Deleted Successfully",
    color: "success",
    position: 'bottom'
  },
  updateTaskMess: {
    message: "Task is Published",
    color: "success",
    position: 'bottom',
  },
  archiveTaskMess: {
    message: "Task is Archived",
    color: "success",
    position: 'bottom',
  },
  signMess: {
    message: "Signature updated",
    color: "success",
    position: 'bottom',
  },
  bankDetailsSavedMess: {
    message: "Bank Details Succesfully Saved",
    color: "success",
    position: 'bottom',
  },
  logoutMess: {
    message: "You have been Logged Out",
    color: "dark",
    position: 'bottom',
  },
  goingToDashboardMess: {
    message: "Taking you to Dashboard",
    color: "success",
    position: 'bottom',
  },
  goingToSelectRoleMess: {
    message: "Select user type to proceed to Dashboard",
    color: "danger",
    position: 'bottom',
  },
  manageTeamMess: {
    message: "Create a Single Tree Hierarchy to Save Project Team",
    color: "danger",
    position: 'bottom',
  },
  manageTeamSavedMess: {
    message: "Organization Structure Saved",
    color: "success",
    position: 'bottom',
  },
  manageTeamUpdatedMess: {
    message: "Organization Structure Updated",
    color: "success",
    position: 'bottom',
  },
  newRoleAddedMess: {
    message: "Role Added Successfully!",
    color: "success",
    position: 'bottom',
  },
  accountActivatedMess: {
    message: "Account Activated Successfully!, Sign In to Continue",
    color: "success",
    position: 'bottom',
  },
  ndaCreatedMess: {
    message: "NDA Created Successfully!",
    color: "success",
    position: 'bottom',
  },
  ndaUpdatedMess: {
    message: "NDA Updated Successfully!",
    color: "success",
    position: 'bottom',
  },
  investmentAmountLengthMess: {
    message: "Maximum of 3 Investment Amounts allowed!",
    color: "danger",
    position: 'bottom',
  },
  investmentAmountUpdatedMess: {
    message: "Investment Amount Updated Successfully!",
    color: "success",
    position: 'bottom',
  },
  agreementCreatedMess: {
    message: "Agreement Created Successfully!",
    color: "success",
    position: 'bottom',
  },
  agreementUpdatedMess: {
    message: "Agreement Updated Successfully!",
    color: "success",
    position: 'bottom',
  },
  agreementDeletedMess: {
    message: "Agreement Deleted Successfully!",
    color: "success",
    position: 'bottom',
  },
  investementDeletedMess: {
    message: "Investment Removed Successfully!",
    color: "success",
    position: 'bottom',
  },
  projectDeletedMess: {
    message: "Project Removed Successfully!",
    color: "success",
    position: 'bottom',
  },
  attachmentDeletedMsg: {
    message: "Attachment Deleted Successfully",
    color: "success",
    position: 'bottom',
  },
  connectReqSentMsg: {
    message: "Connection Request Sent!",
    color: "success",
    position: 'bottom',
  },
  acceptConnectReqMsg: {
    message: "Connection Request Accepted!",
    color: "success",
    position: 'bottom',
  },
  acceptConnectReqAndChatMovedMsg: {
    message: "Connection Request Accepted and Chat Moved to Match Me Section!",
    color: "success",
    position: 'bottom',
  },
  declineConnectReqMsg: {
    message: "Connection Request Declined!",
    color: "dark",
    position: 'bottom',
  },
  connectionInviteErrorMsg: {
    message: "You've already sent a connection request. Please wait for a response!",
    color: "danger",
    position: 'bottom',
  },
  clickAgainToCloseAppMsg: {
    message: "Click again to close InventFunds",
    color: "light",
    position: 'bottom',
  },


}
