import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MessagesComponent } from './messages/messages.component';
import { CoreModule } from '../core/core.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatPanelService } from './messages/services/chat-panel.service';
// import { PusherService } from './messages/services/pusher.service';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { HttpClientModule } from '@angular/common/http';
import { SkeletonModule } from 'primeng/skeleton';
import { VideoCallerComponent } from './video-caller/video-caller.component';
import { DialogModule } from 'primeng/dialog';
import { SharedRoutingModule } from './shared-routing.module';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { ChipModule } from 'primeng/chip';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';



@NgModule({
  declarations: [MessagesComponent,
    VideoCallerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    PickerModule,
    EmojiModule,
    HttpClientModule,
    SkeletonModule,
    DialogModule,
    SharedRoutingModule,
    ChipModule
  ],
  providers: [
    ChatPanelService,
    Keyboard,
    FileOpener,
    File
    // PusherService
  ],
  exports: [
    MessagesComponent,
    VideoCallerComponent,
  ]
})
export class SharedModule { }
