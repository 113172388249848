import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import * as config from '../../../providers/config';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { AppStateService } from 'src/app/services/app-state.service';
import * as moment from 'moment';
import { InventfundsApiService } from 'src/app/services/inventfunds-api.service';
import { LoadingController } from '@ionic/angular';
@Component({
  selector: 'attachment-gallery-m',
  templateUrl: './attachment-gallery-m.component.html',
  styleUrls: ['./attachment-gallery-m.component.scss'],
})
export class AttachmentGalleryMComponent implements OnInit {

  /* ----------------------------------------------------------------------------------
   ** Global variable
  ------------------------------------------------------------------------------------*/

  uploadUrl = config.UPLOADTOS3MOBILE;
  listOfImages = [];
  selectedImage = {
    imageURL: "",
    type: "",
    _id: "",
    index: "",
    status: ""
  }
  @ViewChild('videoRef') videoPlayer: ElementRef;
  timeoutId;
  loadingDelete

  @Input() tableNameToCreate: any

  /* ----------------------------------------------------------------------------------
   ** constructor
  ------------------------------------------------------------------------------------*/
  constructor(private camera: Camera,
    private transfer: FileTransfer,
    private inventFundsApiService: InventfundsApiService,
    private appStateService: AppStateService,
    public loadingController: LoadingController) { }

  /* ----------------------------------------------------------------------------------
   ** ngOnInit
  ------------------------------------------------------------------------------------*/
  ngOnInit() {
    console.log(this.tableNameToCreate)
    this.getUplodedImagesOfPerson();
  }

  /* ----------------------------------------------------------------------------------
   ** Onclick on plus icon to upload images or video
  ------------------------------------------------------------------------------------*/
  upload(type) {

    if (type == 'video') {
      const options: CameraOptions = {
        mediaType: this.camera.MediaType.VIDEO,
        destinationType: this.camera.DestinationType.FILE_URI,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      };
      this.openGalleryGetImage(options, type)
    } else if (type == 'image') {
      const options: CameraOptions = {
        destinationType: this.camera.DestinationType.FILE_URI,
        mediaType: this.camera.MediaType.PICTURE,
        sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      };
      this.openGalleryGetImage(options, type)
    }
  }

  /* ----------------------------------------------------------------------------------
   ** This methos is to open mobile gallery and select img or video
  ------------------------------------------------------------------------------------*/
  openGalleryGetImage(options, type) {
    this.camera.getPicture(options).then(
      (imageData) => {
        this.uploadToserver(imageData, type);
      },
      ((err) => {
        // this.presentAlert()
      })
    );
  }

  /* ----------------------------------------------------------------------------------
   ** This method is to upload img to s3 bucket and get image url
  ------------------------------------------------------------------------------------*/

  async uploadToserver(imageData, type) {
    const loading = await this.presentLoadingUpload();
    var url = this.uploadUrl;
    var options: FileUploadOptions = {
      fileKey: 'ionicfile',
      fileName: 'ionicfile',
      chunkedMode: false,
      mimeType: 'image/jpeg',
    };

    const fileTransfer: FileTransferObject = this.transfer.create();
    fileTransfer.upload(imageData, url, options).then(
      (data) => {
        // alert(data.response)
        var res1 = JSON.parse(data.response);
        this.createAttDataIndb(res1.s3FileLocation, type)
      },
      (err) => {
       this.loadingController.dismiss()
      });
  }

  /* ----------------------------------------------------------------------------------
   ** This method is to create data in database
  ------------------------------------------------------------------------------------*/
  createAttDataIndb(imageUrl, type) {
    var params = {
      collectionName:this.tableNameToCreate,
      updateData: {
        personId: this.appStateService.decryptedDataValue('personId'),
        imageURL: imageUrl,
        createdDate: moment().format(),
        type: type,
        updatedDate: "",
        createdBy: this.appStateService.decryptedDataValue('personId'),
        updatedBy: this.appStateService.decryptedDataValue('personId')
      }
    }
    this.inventFundsApiService.createMongodbData(params).then(res => {
      this.loadingController.dismiss()
      this.getUplodedImagesOfPerson()
    }).catch(err => {
      console.error(err)
      this.loadingController.dismiss()
    })
  }

  /* ----------------------------------------------------------------------------------
   ** This methos is to get all the uploded imgs of person
  ------------------------------------------------------------------------------------*/
  getUplodedImagesOfPerson() {
    this.listOfImages = []
    var params = {
      collectionName: this.tableNameToCreate,
      queryStr: { personId: { $eq: this.appStateService.decryptedDataValue('personId'), } },
    };
    this.inventFundsApiService
      .retrieveMongoDBAll(params)
      .then((res) => {
        console.log("res===== " + JSON.stringify(res))
        this.listOfImages = res.response;
        if (res.response.length >= 1) {
          this.selectedImage.type = this.listOfImages[0].type;
          this.selectedImage.imageURL = this.listOfImages[0].imageURL;
          this.selectedImage._id = this.listOfImages[0]._id;
          this.selectedImage.status = ""
        } else {
          this.selectedImage.type = "image";
          this.selectedImage.imageURL = "";
          this.selectedImage.status = "initial"
        }

      }).catch(err => {
        console.error(err)
      })
  }

  /* ----------------------------------------------------------------------------------
   ** On click of selected image or video
  ------------------------------------------------------------------------------------*/
  getData(data, index) {
    // console.log(JSON.stringify(data._id))
    this.selectedImage.type = data.type;
    this.selectedImage.imageURL = data.imageURL;
    this.selectedImage._id = data._id;
    this.selectedImage.index = index
  }
  /* ----------------------------------------------------------------------------------
   ** This method is to delete img or video from database and s3 bucket
  ------------------------------------------------------------------------------------*/

  deleteImage(item) {
    console.log(JSON.stringify(item))
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
    this.timeoutId = setTimeout((data) => {
      this.deleteImageFromDb(item)
    }, 500)

  }

  deleteImageFromDb(item) {
    this.presentLoadingDelete()
    var params = {
      "collectionName": this.tableNameToCreate,
      "pkId": item._id
    }
    this.inventFundsApiService.deleteMongodbData(params).then((result) => {
      console.log("res" + JSON.stringify(result))
      this.deleteImageFromS3Bucket(item)
    }).catch(err => {
      console.error(err)
      this.loadingController.dismiss();
    })
  }
  deleteImageFromS3Bucket(item) {
    var fileData = {
      imageArray: [{
        "awsS3FileLocation": item.imageURL
      }],
    };

    this.inventFundsApiService
      .deleatAttachmentFile(fileData)
      .then((res: any) => {
        console.log('res.........' + JSON.stringify(res));
        if (res.data.code == 200) {
          this.loadingController.dismiss();
          this.getUplodedImagesOfPerson()
        }
      })
      .catch((err) => {
        console.log('Error:........' + JSON.stringify(err));
        this.loadingController.dismiss();
      });
  }

  async presentLoadingDelete() {
    this.loadingDelete = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Deleting...',
      spinner: "lines"
    });
    await this.loadingDelete.present();
  }

  async presentLoadingUpload() {
    var load = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Upload...',
      spinner: "lines"
    });
    await load.present();
  }


}
