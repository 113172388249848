import {AfterContentInit, Directive,ElementRef,Input} from '@angular/core';


@Directive({
  selector: '[autofocus]'
})
export class AutoFocusDirectiveDirective {
  @Input() public appAutoFocus: boolean;

  constructor(private elementRef: ElementRef) { }

public ngAfterContentInit(){
    this.elementRef.nativeElement.focus();
}

}
