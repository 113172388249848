import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  /* ----------------------------------------------------------------------------------
   ** Constructor
 ------------------------------------------------------------------------------------*/
  constructor(
    public navCtrl: NavController,

    public popoverController: PopoverController,
    private appStateService: AppStateService,

    @Inject('windowObject') private window: Window
  ) { }

  ngOnInit() { }

}

export const helpContent = {

  PROJECTTEAMS:{
    INFO:{title:"Structure of company and their different roles ",
          content:"charts are often used in project management to show the team structure and roles of members working on a project . Its also be used to visualize the structure of organization in a software applicationS.  It is typically used to visualize the hierarchy of an organization and  roles and responsibilities of its employees."}
  },
  GENERALINFORMATION: {
    BASICINFO: { title: "Basic Info", content: "Basic Info Description" },
    CHANGEPW: { title: "Change Password", content: "Change Password Description" },
  },
  MYBANKDETAILS: {
    SELECTBANK: { title: "Select Bank", content: "Select Bank Description" },
    ACCOUNTNUMBER: { title: "Account Number", content: "Account Number Description" },
    IBANNUMBER: { title: "IBAN Number", content: "IBAN Number Description" },
  },

  GENERALINFO: {
    COMPANYNAME: { title: "Company Name", content: "Company Name Description" },
    EIN: { title: "EIN", content: "EIN Description" },
    COMPANYDESCRIPTION: { title: "Company", content: "Company Description" },
    COMPANYVISION: { title: "Vision", content: "Vision Description" },
    COMPANYMISSION: { title: "Mission", content: "Mission Description" },
    COMPANYSERVICES: { title: "Services", content: "Services Description" },
    ADDRESS: { title: "Address", content: "Address Description" },
    CHARGES: { title: "Charges", content: "Charges Description" },
    EXPERIENCE: { title: "Experience", content: "Experience Description" },
    INDUSTRY: { title: "Industry", content: "Industry Description" },
    PROJECTSTAGE: { title: "Project Stage", content: "Project Stage Description" },
    GEOGRAPHIC: { title: "Geographic", content: "Geographic Description" },
    INVESTMENTSIZE: { title: "Investment Size", content: "Investment Size Description" },

  },

  MYCUSTOMERS: {
    CUSTOMERTITLE: { title: "My Customer", content: "My Customer title Description" },
    CUSTOMERDESCRIPTION: { title: "My Customer Description", content: "My Customer Description" },
  },

  TARGETCUSTOMERS: {
    TARGETCUSTOMERTITLE: { title: "Target Customer", content: "Target Customer title Description" },
    TARGETCUSTOMERDESCRIPTION: { title: "Target Customer Description", content: "Target Customer Description" },
  },

  EXPERIENCE: {
    WORKEXPERIENCE: { title: "Work Experience", content: "Work Experience Description" },
    WORKDURATION: { title: "Work Duration", content: "Work Duration Description" },
    WORKSUMMARISE: { title: "Work Summarise", content: "Work Summarise Description" },
  },

  CERTIFICATION: {
    CERTIFICATENAME: { title: "Certification", content: "Certification Description" },
    COMPANYNAME: { title: "Company Name", content: "Company Name Description" },
    ISSUEDATE: { title: "Issue Date", content: "Issue Date Description" },
    EXPIRYDATE: { title: "Expiry Date", content: "Expiry Date Description" },
    CREDENTIALID: { title: "Credential Id", content: "Credential Id Description" },
    CREDENTIALLINK: { title: "Credential Link", content: "Credential Link Description" },
  },

  NDA: {
    NDATITLE: { title: "NDA", content: "NDA Title Description" },
    NDACONTENT: { title: "NDA Content", content: "NDA Content Description" },
    NDANAME: { title: "NDA Name", content: "NDA Name Description" },
    NDASIGNATURE: { title: "NDA Signature", content: "NDA Signature Description" },
  },

  SIGNEDNDA: {
    COMPANYNAME: { title: "Company Name", content: "Company Name Description" },
    NAMEOFSIGNEE: { title: "Name of Signee", content: "Name of Signee Description" },
  },

  CREATENEWS: {
    NEWSTITLE: { title: "News", content: "News title Description" },
    NEWSCATEGORY: { title: "News Category", content: "News Category Description" },
  },

  ADDEVENT: {
    EVENTNAME: { title: "Event Name", content: "Event Name Description" },
    STARTTIME: { title: "Start Time", content: "Start Time Description" },
    ENDTIME: { title: "End Time", content: "End Time Description" },
    DESCRIPTION: { title: "Event Description", content: "Event Description" },
    MAILID: { title: "Mail Id", content: "Mail Id Description" },
  },

  EDITEVENT: {
    EVENTNAME: { title: "Event Name", content: "Event Name Description" },
    STARTTIME: { title: "Start Time", content: "Start Time Description" },
    ENDTIME: { title: "End Time", content: "End Time Description" },
    DESCRIPTION: { title: "Event Description", content: "Event Description" },
    MAILID: { title: "Mail Id", content: "Mail Id Description" },
  },

  HELPMODE: {
    HELPINFO: { title: "Help Mode", content: "Help Mode Description" },

  },

  CREATEIDEA: {
    TITLE: { title: "Create Idea", content: "Create Idea Description" },
    DESCRIPTION: { title: "Description", content: "Create Idea Description" },
    PROBLEMSTATEMENT: { title: "Problem Statement", content: "Problem Statement Description" },
    TARGETAUDIENCE: { title: "Target Audience", content: "Target Audience Description" },
    RESOURCESNEEDED: { title: "Resources Needed", content: "Resources Needed Description" },
    TIMELINE: { title: "Timeline", content: "Timeline Description" },
    KEYPERFORMANCEINDICATORS: { title: "Key Performance Indicators", content: "Key Performance Indicators Description" },
    UNIQUESELLINGPROPOSITION: { title: "Unique Selling Proposition", content: "Unique Selling Proposition Description" },
  },

}
