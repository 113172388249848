import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() item;
  @Output() newItemEvent = new EventEmitter<any>();
  moreInfo: boolean = false;
  moreInfoLength: number = 60;
  summary: string;
  summaryTruncated: string;
  showMoreRequired: boolean = false;
  constructor() {}

  ngOnInit() {
    if (this.item.description) {
      this.summary = this.item.description;
      let summaryLength = this.summary.length;
      if (summaryLength > this.moreInfoLength) {
        this.showMoreRequired = true;
        this.summaryTruncated =
        this.summary.substring(0, this.moreInfoLength) + '...';
      }
    }
  }

  toggleMoreInfo() {
    this.moreInfo = !this.moreInfo;
    if (this.moreInfo) {
      this.summaryTruncated = this.summary + ' ';
    } else {
      this.summaryTruncated =
        this.summary.substring(0, this.moreInfoLength) + '... ';
    }
  }

  output(_id: string, category: string) {
    this.newItemEvent.emit({ _id, category });
    console.log(_id, category);
  }
}
