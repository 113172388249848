import { Component, OnInit } from '@angular/core';
import { Output,Input, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-chip-services',
  templateUrl: './chip-services.component.html',
  styleUrls: ['./chip-services.component.scss'],
})
export class ChipServicesComponent implements OnInit {
  @Input() item;

  constructor() { }
  
  ngOnInit() {}
 
}