import { Component, HostListener, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ModalPageComponent } from '../modal-page/modal-page.component';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss'],
})
export class MobileFooterComponent implements OnInit {

  modalOpen = false;

/* ----------------------------------------------------------------------------------
** constructor
------------------------------------------------------------------------------------*/
  constructor(public modalController: ModalController,
    public navCtrl: NavController) { }

/* ----------------------------------------------------------------------------------
** ngOnInit
------------------------------------------------------------------------------------*/
  ngOnInit() { }

/* ----------------------------------------------------------------------------------
** Opem modal window method
------------------------------------------------------------------------------------*/
  async presentModal() {

    if (this.modalOpen == false) {
      const modal = await this.modalController.create({
        component: ModalPageComponent,
        // backdropDismiss:false,
        mode: "ios",
        cssClass: 'my-custom-modal-css'
      });
      return await modal.present().then(() => {
        this.modalOpen = true
      });
    } else {
      this.modalController.dismiss({
        'dismissed': true
      });
      this.modalOpen = false
    }

  }

/* ----------------------------------------------------------------------------------
** Route to timeline component
------------------------------------------------------------------------------------*/
  goToTimeline() {
    if (this.modalOpen == true) {
      this.modalController.dismiss({
        'dismissed': true
      });
      this.modalOpen = false
      this.navCtrl.navigateBack(['/timeline']);
    } else {
      this.navCtrl.navigateBack(['/timeline']);
    }

  }

/* ----------------------------------------------------------------------------------
** Route to discover component
------------------------------------------------------------------------------------*/
  goToDiscover() {
    if (this.modalOpen == true) {
      this.modalController.dismiss({
        'dismissed': true
      });
      this.modalOpen = false
      this.navCtrl.navigateBack(['/dashboard']);
    } else {
      this.navCtrl.navigateBack(['/dashboard']);
    }

  }


}
