import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { NavController, PopoverController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from 'angularx-social-login';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-modal-page',
  templateUrl: './modal-page.component.html',
  styleUrls: ['./modal-page.component.scss'],
})
export class ModalPageComponent implements OnInit {
  modalOpen: boolean = false;

  /* ----------------------------------------------------------------------------------
   ** Constructor
 ------------------------------------------------------------------------------------*/
  constructor(public navCtrl: NavController,
    private modalController: ModalController,
    public popoverController: PopoverController,
    private appStateService:AppStateService,
    private googlePlus: GooglePlus,
    private facebook: Facebook,
    private authService: SocialAuthService,
    @Inject('windowObject') public window: Window) { }
    

  /* ----------------------------------------------------------------------------------
   ** ngOnInit
 ------------------------------------------------------------------------------------*/
  ngOnInit() { }

  /* ----------------------------------------------------------------------------------
  ** Method to navigate profile page
  ------------------------------------------------------------------------------------*/

  profilePage() {
    this.modalController.dismiss({
      'dismissed': true
    });
    this.navCtrl.navigateBack(['/profile']);
  }

  profilePageWeb() {
   this.popoverController.dismiss()
    this.navCtrl.navigateBack(['/profile']);
  }

  logout(){
    let email = localStorage.getItem('email')
    localStorage.clear();
    this.appStateService.resetSignUpdetails();
    this.appStateService.resetloginData();
    this.appStateService.restForgotPwd();
    this.appStateService.resetGlobalData();
    this.googlePlus
      .disconnect()
      .then((msg) => { })
      .catch((msg) => { });
    this.facebook
      .logout()
      .then((res) => { })
      .catch((err) => { });
    this.authService
      .signOut(true)
      .then((res) => {
        console.log('res' + JSON.stringify(res));
      })
      .catch((err) => {
        console.log('err' + JSON.stringify(err));
      });
    localStorage.setItem('email', email);
    // this.router.navigate(['/sign-in']);
  
    this.modalController.dismiss({
      'dismissed': true
    });
    this.popoverController.dismiss()
    this.navCtrl.navigateBack(['/sign-in']);
    window.location.reload();
  }

  /* ----------------------------------------------------------------------------------
  ** Method to navigate settings page
  ------------------------------------------------------------------------------------*/

  settingsPage(){
    this.modalController.dismiss({
      'dismissed': true
    });
    this.navCtrl.navigateBack(['/settings']);
  }

  settingsPageW(){
    this.popoverController.dismiss()
    this.navCtrl.navigateBack(['/settings']);
  }

  goToFollow() {
    this.modalController.dismiss({
      'dismissed': true
    });
    this.navCtrl.navigateBack(['/follow']);
  }

  goToFollowWeb(){
    this.popoverController.dismiss()
    this.navCtrl.navigateBack(['/follow']);
  }


  goToIdea() {
    this.modalController.dismiss({
      'dismissed': true
    });
    this.navCtrl.navigateBack(['/savedidea']);
  }

  goToIdeaWeb(){
    this.popoverController.dismiss()
    this.navCtrl.navigateBack(['/savedidea']);
  }

  close() {
    // console.log("abc" + this.modalOpen)
    if (this.modalOpen == true) {
      this.modalController.dismiss({
        'dismissed': true
      });
      this.modalOpen = false
    }
  }

}
