import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as config from '../../providers/config';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';


@Injectable({
  providedIn: 'root'
})
export class InventfundsApiService {

  constructor(private _httpClient: HttpClient,) {

  }
  private newUser = new BehaviorSubject<any>({

  });
  getEndPoint(endPoint) {
    return config[endPoint];
  }


  retrieveMongoDBAll(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.RETRIEVEMONGOALL, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  retrieveMongoDBAllWithMask(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.RETRIEVEMONGOALLANDMASK, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }



  retrieveMongoDBOne(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.RETRIEVEMONGOONE, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  createMongodbData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.CREATEMONGODATA, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  sendEventEmail(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SENDEVENTEMAIL, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }
  sendInviteEmail(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SENDINVITEEMAIL, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  updateMongodbEvent(params): Promise<any> {
    console.log("UPDATE MONGO DB EVENT", params)
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEMONGOEVENT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  updateMongodbData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEMONGODATA, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  deleteMongodbData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.DELETEMONGODATA, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  deleteMongodbEvent(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.DELETEMONGOEVENT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  sendOtp(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SENDOTP, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  sendPdf(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SENDPDF, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  signUp(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SIGNUP, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  signIn(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SIGNIN, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  updateRoleAndOrgDetails(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEORGANDROLE, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  changePassword(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.CHANGEPASSWORD, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getToken(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GETTOKEN, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  forgotPassword(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.FORGOTPASSWORD, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  retrievingLookUpData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.RETRIEVELOOKUP, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  retrieveSignedAttachment(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GETSIGNEDATTACHMENT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  };


  attachmentSingleFile(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.SINGLEATTACHMENT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  attachmentUpdateSingleFile(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEATTACHMENT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  retrieveAllUserData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.RETRIEVEALLUSER, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  updateWorkSummary(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEWORKSUMMARY, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  uploadImageS3Profile(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPLOADS3PROFILE, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  retrieveGeneric(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(this.getEndPoint(params.endPoint), params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  updatePassword(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.UPDATEPWD, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  deleatAttachmentFile(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.DELETEATTACHMENT, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  getProfileData(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GETPROFILEDATA, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }

  addCustomSkill(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.ADDCUSTOMSKILL, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }
  addCustomSkillInPersonTbl(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.ADDCUSTOMSKILLINPERSON, params)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });

  }

  // Old Method
  // createMeeting(params): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient
  //       .post(config.CREATEMEETING, params)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, reject);
  //   });
  // };

  // New method
  createMeeting(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.CREATEMEETING, params)
        .subscribe(
          (response: any) => {
            console.log("Backend Response:", response); // Debugging line
            resolve(response); // Return the entire response
          },
          (error) => {
            console.error("Error in HTTP request:", error);
            reject(error);
          }
        );
    });
  }

  // Old Method
  // generateSignature(params): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient
  //       .post(config.GENERATESIGNATURE, params)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, reject);
  //   });
  // };

  // New method
  generateSignature(params): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(config.GENERATESIGNATURE, params)
        .subscribe(
          (response: any) => {
            console.log("Backend Response:", response); // Debugging line to check the backend response
            resolve(response);
          },
          (error) => {
            console.error("Error in HTTP request:", error);
            reject(error);
          }
        );
    });
  }


  setNewUserInfo(user: any) {
    this.newUser.next(user);
  }

  getNewUserInfo() {
    return this.newUser.asObservable();
  }
}





