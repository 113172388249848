import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';



@Component({
  selector: 'verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOtpComponent implements OnInit {

  /* ----------------------------------------------------------------------------------
  ** Global varibles
  ------------------------------------------------------------------------------------*/
  arr = []
  otpVerify: FormGroup;
  isLoaded: boolean = false;
  // @ViewChild('codeBox1') codeBox1: ElementRef;
  @ViewChild('codeBox1', { static: true }) codeBox1: ElementRef;

  @Output() emitOtp = new EventEmitter();
  @Input() set invalidOtp(value) {
    console.log(value)
    if (value != undefined) {
      var ele = document.getElementById('codeBox1');
      this.otpVerify.reset();
      ele.focus()
    }
  }

  //@ViewChild('codeBox1') test: ElementRef;
 
  /* ----------------------------------------------------------------------------------
  ** constructor
  ------------------------------------------------------------------------------------*/

  constructor( @Inject('windowObject') private window: Window) {
    this.otpVerify = new FormGroup({
      value1: new FormControl(
        null,
        Validators.compose([
          Validators.maxLength(6),
          Validators.pattern('^[0-9]{1,1}$'),
        ])
      ),
      value2: new FormControl(
        null,
        Validators.compose([
          Validators.maxLength(1),
          Validators.pattern('^[0-9]{1,1}$'),
        ])
      ),
      value3: new FormControl(
        null,
        Validators.compose([
          Validators.maxLength(1),
          Validators.pattern('^[0-9]{1,1}$'),
        ])
      ),
      value4: new FormControl(
        null,
        Validators.compose([
          Validators.maxLength(1),
          Validators.pattern('^[0-9]{1,1}$'),
        ])
      ),
      value5: new FormControl(
        null,
        Validators.compose([
          Validators.maxLength(1),
          Validators.pattern('^[0-9]{1,1}$'),
        ])
      ),
      value6: new FormControl(
        null,
        Validators.compose([
          Validators.maxLength(1),
          Validators.pattern('^[0-9]{1,1}$'),
        ])
      ),
    });
  }

  /* ----------------------------------------------------------------------------------
  ** ngOnInit
  ------------------------------------------------------------------------------------*/
  ngOnInit() {
    this.getAutoFocus();
   }



  getAutoFocus() {
    setTimeout(function(){document.getElementById('codeBox1').focus()},500);
    
}

// ngOnChanges(changes: SimpleChanges){
//   if(changes.items) {
//   this.codeBox1.nativeElement.firstChild['autofocus'] = 'true';
// }
// }

  verifyOtpmobile() {
    // alert("uuu")
    this.arr=[]
    var abc =  this.otpVerify.controls['value1'].value.toString()
    // alert(abc)
    // alert(abc.is)
    for (let x of abc) {
      console.log(x)
      this.arr.push(x)
    }
    if(this.arr.length == 1){

    }else{
      this.otpVerify.controls['value1'].setValue(this.arr[0]);
      this.otpVerify.controls['value2'].setValue(this.arr[1]);
      this.otpVerify.controls['value3'].setValue(this.arr[2]);
      this.otpVerify.controls['value4'].setValue(this.arr[3]);
      this.otpVerify.controls['value5'].setValue(this.arr[4]);
      this.otpVerify.controls['value6'].setValue(this.arr[5]);
      this.getCodeBoxElement(5).focus();
      setTimeout(()=>{
        this.emitOtp.emit(abc)
      },200)
    }


   
  }

  // otp verification

  onKeyUpEvent(index, event) {
    console.log(
      'length of char : ' +
      JSON.stringify(this.otpVerify.controls['value1'].value)
    );
    console.log('key up' + this.getCodeBoxElement(index));
    const eventCode = event.which || event.keyCode;
    if (
      (this.getCodeBoxElement(index) as HTMLTextAreaElement).value.length === 1
    ) {
      console.log(index);
      if (index !== 6) {
        this.getCodeBoxElement(index + 1).focus();
      } else {
        this.getCodeBoxElement(index).blur();
        // Submit code
        console.log('submit code : ' + JSON.stringify(this.otpVerify.value));
        var otpVerify = Object.values(this.otpVerify.value).join('');
        console.log('otpVerify : ' + JSON.stringify(otpVerify));
        this.emitOtp.emit(otpVerify)

      }
    }
    if (eventCode === 8 && index !== 1) {
      this.getCodeBoxElement(index - 1).focus();
    }
  }

  // on focus event

  onFocusEvent(index) {
    for (var item = 1; item < index; item++) {
      const currentElement = this.getCodeBoxElement(
        item
      ) as HTMLTextAreaElement;
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  }


  // get code box element

  getCodeBoxElement(index) {
    return document.getElementById('codeBox' + index);
  }

}
