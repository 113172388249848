import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-card-set',
  templateUrl: './card-set.component.html',
  styleUrls: ['./card-set.component.scss'],
})
export class CardSetComponent implements OnInit {

/* -----------------------------------------------------------------------------------------------------------------------
       ** Global variable
--------------------------------------------------------------------------------------------------------------------------*/
moreInfo: boolean = false;

  @Input() item;
  @Input() routeForEditingExperience;
  @Output() newItemEvent = new EventEmitter<any>();

/* --------------------------------------------------------------------------------------------------------------------------
  ** constructor
  --------------------------------------------------------------------------------------------------------------------------*/


  constructor(public navCtrl: NavController) { }


/* ----------------------------------------------------------------------------------------------------------------------------
  ** constructor
  ----------------------------------------------------------------------------------------------------------------------------*/

 /* -----------------------------------------------------------------------------------------------------------------------------
  ** ngOnInit
  -------------------------------------------------------------------------------------------------------------------------------*/
  
  ngOnInit() {}
  
  addNewItem(_id: string,value:string) {
    this.newItemEvent.emit({_id,value});
    console.log(_id);
  }

 /* -----------------------------------------------------------------------------------------------------------------------------
  ** ngOnInit Ends
  -------------------------------------------------------------------------------------------------------------------------------*/

}
  
  
