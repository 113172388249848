import { AttachmentWebProfileComponent } from './attachment-web-profile/attachment-web-profile.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { FileUploadModule } from 'ng2-file-upload';
import { MobileFooterComponent } from './mobile-footer/mobile-footer.component';
import { ModalPageComponent } from './modal-page/modal-page.component';
import { IonicModule } from '@ionic/angular';
import { Facebook } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { WebHeaderComponent } from './web-header/web-header.component';
import { AttachmentWebComponent } from './attachment-web/attachment-web.component';
import { AttachmentGalleryMComponent } from './attachment-gallery-m/attachment-gallery-m.component';
import { CardSetComponent } from './card-set/card-set.component';
import { CardSetLiteComponent } from './card-set-lite/card-set-lite.component';
import { ChipServicesComponent } from './chip-services/chip-services.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { IonicRatingComponentModule } from 'ionic-rating-component';
import { InfoPopoverComponent } from './info-popover/info-popover.component';
import { DateComponent } from './date-component/date-component.component';
import { ProjectCardComponent } from './project-card/project-card.component'
import { SingleAttachmentComponent } from './single-attachment/single-attachment.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ClipboardDialogComponent } from './clipboard-dialog/clipboard-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { FileViewerComponent } from './file-viewer/file-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    IonicModule,
    MatProgressBarModule,
    ClipboardModule,
    DialogModule,
    NgxDocViewerModule
  ],
  providers: [
    Camera,
    FileTransfer,
    File,
    Facebook,
    GooglePlus,
    SignInWithApple,
  ],

  declarations: [
    DateComponent,
    VerifyOtpComponent,
    ChipServicesComponent,
    CardSetLiteComponent,
    CardSetComponent,
    AttachmentComponent,
    AttachmentWebComponent,
    MobileFooterComponent,
    ModalPageComponent,
    WebHeaderComponent,
    AttachmentGalleryMComponent,
    ReviewsComponent,
    InfoPopoverComponent,
    AttachmentWebProfileComponent,
    ProjectCardComponent,
    SingleAttachmentComponent,
    ClipboardDialogComponent,
    FileViewerComponent
  ],
  exports: [
    DateComponent,
    VerifyOtpComponent,
    ChipServicesComponent,
    CardSetLiteComponent,
    CardSetComponent,
    AttachmentComponent,
    AttachmentWebComponent,
    MobileFooterComponent,
    WebHeaderComponent,
    ReviewsComponent,
    InfoPopoverComponent,
    AttachmentGalleryMComponent,
    AttachmentWebProfileComponent,
    ProjectCardComponent,
    SingleAttachmentComponent,
    ClipboardDialogComponent,
    FileViewerComponent

  ],
})
export class CoreModule {}
