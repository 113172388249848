import { Inject, Injectable, OnInit } from '@angular/core';
import { InventfundsApiService } from './inventfunds-api.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as crypto from 'crypto-js';
import { AlertController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class AppStateService implements OnInit {
  deviceInfo;
  deviceToken;
  encryptedData;
  roleCode;
  screenSize: number;
  PLATFORM = 'ANDROID';
  avatar$: BehaviorSubject<any>;
  userName$: BehaviorSubject<any>;
  profileImageAfterSet = 'assets/profile-placeholder.png';
  profileImage = '';
  profileImageSigned: any;

  senderId = '';
  receiverId = '';

  /* ----------------------------------------------------------------------------------
   ** constructor
  ------------------------------------------------------------------------------------*/
  constructor(
    private inventFundsApiService: InventfundsApiService,
    private deviceService: DeviceDetectorService,
    public alertController: AlertController,
    @Inject('windowObject') private window: Window,
    public toastController: ToastController
  ) {
    this.avatar$ = new BehaviorSubject('');
    this.userName$ = new BehaviorSubject(this.globalData.personName);
    this.screenSize = window.innerWidth;
    console.log('this.screenSize from app service ---> ' + this.screenSize);
    if (localStorage.getItem('profile') != null) {
      this.decryptProfileJson();
    }
    this.roleCode = this.decryptedDataValue('roleCode');
  }

  /* ----------------------------------------------------------------------------------
   ** ngOnInit
  ------------------------------------------------------------------------------------*/
  ngOnInit() {}

  signupDetails = {
    email: '',
    name: '',
    password: '',
    firstName: '',
    lastName: '',
    imageUrl: null,
    type: 'EXPLORER',
    provider: '',
    passwordRequired: '',
    phoneNumber: '',
    countryCode: '',
    number: '',
    termsAndConditionChecked: false
  };

  globalData = {
    roleId: '',
    roleCode: '',
    partyId: '',
    personId: '',
    personEmail: '',
    personType: '',
    orgId: '',
    partyCode: '',
    token: '',
    personPhoneNo: '',
    personName: '',
    imageURL: '',
    signUpProvider: '',
    name: '',
    countryCode: '',
    number: '',
    passWordType: '',
    signUpType: '',
    userId: '',
    personChannelId: '',
    fixerType: '',
    companyPersonId: ''
  };

  loginDetails = {
    passWordType: '',
    partyId: '',
    personId: '',
    signUpType: '',
    personEmail: '',
    personType: '',
    name: '',
    countryCode: '',
    number: '',
    url: '',
    path: '',
    key: '',
    token: '',
    roleId: '',
    roleCode: '',
    partyCode: '',
    orgId: '',
    phoneNumber: '',
    signUpProvider: '',
  };

  forgotPwd = {
    userId: '',
    personId: '',
  };
  encryptSecretKey: any = 'KA0f2100e34b54d5VA0fd013df8f300d3497H579O';
  encryptOptions = { mode: crypto.mode.CBC, padding: crypto.pad.Pkcs7 };

  decryptProfileJson() {
    console.log(
      'profile data : ' + JSON.stringify(localStorage.getItem('profile'))
    );
    var decrypted = crypto.AES.decrypt(
      localStorage.getItem('profile'),
      this.encryptSecretKey,
      this.encryptOptions
    );
    if (decrypted.toString()) {
      var profileJson = JSON.parse(decrypted.toString(crypto.enc.Utf8));
      this.encryptedData = profileJson;
      console.log('profile data : ' + JSON.stringify(this.encryptedData));
      this.globalData.token = this.encryptedData.token;
      this.globalData.roleId = this.encryptedData.roleId;
      this.globalData.roleCode = this.encryptedData.roleCode;
      this.globalData.partyId = this.encryptedData.partyId;
      this.globalData.personId = this.encryptedData.personId;
      this.globalData.personEmail = this.encryptedData.personEmail;
      this.globalData.personType = this.encryptedData.personType;
      this.globalData.orgId = this.encryptedData.orgId;
      this.globalData.partyCode = this.encryptedData.partyCode;
      this.globalData.signUpProvider = this.encryptedData.signUpProvider;
      this.globalData.name = this.encryptedData.name;
      this.globalData.imageURL = this.encryptedData.imageURL;
      this.globalData.passWordType = this.encryptedData.passWordType;
      this.globalData.countryCode = this.encryptedData.countryCode;
      this.globalData.signUpType = this.encryptedData.signUpType;
      this.globalData.personName = this.encryptedData.name;
      this.globalData.personPhoneNo = this.encryptedData.personPhoneNo;
      this.globalData.number = this.encryptedData.number;
      this.globalData.userId = this.encryptedData.userId;
    }
  }

  decryptedDataValue(value) {
    if (this.encryptedData) {
      return this.encryptedData[value];
    }
  }

  async genericAlert(obj) {
    const alert = await this.alertController.create({
      cssClass: obj.css,
      header: obj.header,
      mode: 'ios',
      subHeader: obj.subHeader,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async genericToast(obj) {
    const toast = await this.toastController.create({
      message: obj.message,
      duration: 4000,
      color: obj.color,
      mode: 'ios',
      position: obj.position,
      cssClass: 'toast-msg',
      animated: true,
    });
    toast.present();
  }

  resetloginData() {
    this.loginDetails = {
      passWordType: '',
      partyId: '',
      personId: '',
      signUpType: '',
      personEmail: '',
      personType: '',
      name: '',
      countryCode: '',
      number: '',
      url: '',
      path: '',
      key: '',
      token: '',
      roleId: '',
      roleCode: '',
      partyCode: '',
      orgId: '',
      phoneNumber: '',
      signUpProvider: '',
    };
  }

  resetSignUpdetails() {
    this.signupDetails = {
      email: '',
      name: '',
      password: '',
      firstName: '',
      lastName: '',
      imageUrl: null,
      type: 'EXPLORER',
      provider: '',
      passwordRequired: '',
      phoneNumber: '',
      countryCode: '',
      number: '',
      termsAndConditionChecked: false
    };
  }
  resetGlobalData() {
    this.globalData = {
      roleId: '',
      roleCode: '',
      partyId: '',
      personId: '',
      personEmail: '',
      personType: '',
      orgId: '',
      partyCode: '',
      token: '',
      personPhoneNo: '',
      personName: '',
      imageURL: '',
      signUpProvider: '',
      name: '',
      countryCode: '',
      number: '',
      passWordType: '',
      signUpType: '',
      userId: '',
      personChannelId: '',
      fixerType: '',
      companyPersonId: ''
    };
  }

  restForgotPwd() {
    this.forgotPwd = {
      userId: '',
      personId: '',
    };
  }

  getProfileImage() {
    if (sessionStorage.getItem('profileImage')) {
      this.profileImageAfterSet = sessionStorage.getItem('profileImage');
      return this.profileImage;
    } else {
      this.profileImageAfterSet = 'assets/profile-placeholder.png';
      return this.profileImage;
    }
  }


  setProfileImage(data) {
    // console.log('data profile image : ' + JSON.stringify(data));
    this.profileImageAfterSet = data;
  }

  // get an profile avatar after uploading in profile settings

  getAvatar() {
    return this.avatar$.asObservable();
  }

  // to set the avatar after changing the profile image

  setAvatar(value: any) {
    this.avatar$.next(value);
  }

  getName() {
    return this.userName$.asObservable();
  }

  // to set the avatar after changing the profile image

  setName(value: any) {
    this.userName$.next(value);
  }
}
