import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-set-lite',
  templateUrl: './card-set-lite.component.html',
  styleUrls: ['./card-set-lite.component.scss'],
})
export class CardSetLiteComponent implements OnInit {

/* -----------------------------------------------------------------------------------------------------------------------
       ** Global variable
--------------------------------------------------------------------------------------------------------------------------*/

  @Input() item;
  @Output() newItemEvent = new EventEmitter<any>();
  credentialUrl : boolean = false;

/* ----------------------------------------------------------------------------------------------------------------------------
  ** constructor
  ----------------------------------------------------------------------------------------------------------------------------*/


  constructor(public navCtrl: NavController) { }

/* ----------------------------------------------------------------------------------------------------------------------------
  ** constructor
  ----------------------------------------------------------------------------------------------------------------------------*/

  /* -----------------------------------------------------------------------------------------------------------------------------
  ** ngOnInit
  -------------------------------------------------------------------------------------------------------------------------------*/
  ngOnInit() {
    console.log(this.item.credentialUrl);
    if(this.item.credentialUrl==undefined){
      this.credentialUrl=true;
    }else{
      this.credentialUrl=false;
    }
  }

  addNewItem(_id: string,value:string) {
    this.newItemEvent.emit( {_id,value} );
    console.log(_id);
  }



 /* -----------------------------------------------------------------------------------------------------------------------------
  ** ngOnInit
  -------------------------------------------------------------------------------------------------------------------------------*/
  
}
