import { FounderOnboardingModule } from './founder-onboarding/founder-onboarding.module';
import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuardService } from './services/auth-guad.service';
import { AuthguardWebService } from './services/authguard-web.service';
import { SavedideasComponent } from './saved-ideas/savedideas/savedideas.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },

  {
    path: 'sign-up',
    loadChildren: () =>
      import('./sign-up/sign-up.module').then((m) => m.SignUpComponentModule),
  },
  {
    path: 'invite-sign-up',
    loadChildren: () =>
      import('./sign-up-invite/sign-up-invite.module').then((m) => m.SignUpInviteModule),
  },
  {
    path: 'sign-in',
    loadChildren: () =>
      import('./sign-in/sign-in.module').then((m) => m.SignInModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      canActivate: [AuthguardWebService],
  },
  {
    path:'landingpage',
    loadChildren: () =>
    import('./landingpage/landingpage.module').then(m => m.LandingpageModule),

  },

{
  path: 'showcase',
  loadChildren: () =>
  import('./showcase/showcase.module').then(m => m.ShowcaseModule),
  canActivate: [AuthguardWebService],

},

{
  path: 'explore',
  loadChildren: () =>
  import('./explore/explore.module').then(m => m.ExploreModule),
  canActivate: [AuthguardWebService],

},
  // {
  //   path: 'timeline',
  //   loadChildren: () =>
  //     import('./timeline/timeline.module').then((m) => m.TimelineModule),
  // },
  // {
  //   path: 'manage-dashboard',
  //   loadChildren: () =>
  //    import('./manage-dashboard/manage-dashboard.module')
  // },

  {
    path: 'discover',
    loadChildren: () => import('./discover/discover.module').then( m => m.DiscoverModule),
    canActivate: [AuthguardWebService],

  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
      canActivate: [AuthguardWebService],
  },
  {
    path: 'user-dashboard',
    loadChildren: () => import('./user-dashboard/user-dashboard.module').then( m => m.UserDashboardModule),
    canActivate: [AuthguardWebService],

  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxModule),
    canActivate: [AuthguardWebService],

  },
  {
    path: 'follow',
    loadChildren: () => import('./follow/follow.module').then( m => m.FollowModule),
    canActivate: [AuthguardWebService],

  },
  {
    path: 'savedidea',
    loadChildren: () => import('./saved-ideas/saved-ideas.module').then( m => m.SavedIdeasModule),
    canActivate: [AuthguardWebService],

  },

  {
    path: 'profile-details',
    loadChildren: () =>
      import('./profile-details/profile-details.module').then(
        (m) => m.ProfileDetailsModule
      ),
      canActivate: [AuthguardWebService],
  },
  {
    path: 'profile-view',
    loadChildren: () =>
      import('./profile-view-others/profile-view-others.module').then(
        (m) => m.ProfileOtherViewModule
      ),
      canActivate: [AuthguardWebService],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
      canActivate: [AuthguardWebService],
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./shared/shared.module').then((m) => m.SharedModule),
      canActivate: [AuthguardWebService],
  },

  {
    path: 'fixer-onboarding',
    loadChildren: () =>
      import('./fixer-onboarding/fixer-onboarding.module').then(
        (m) => m.FixerOnboardingModule
      ),
      canActivate: [AuthguardWebService],
  },

  {
    path: 'founder-onboarding',
    loadChildren: () =>
      import('./founder-onboarding/founder-onboarding.module').then(
        (m) => m.FounderOnboardingModule
      ),
      canActivate: [AuthguardWebService],
  },
  {
    path: 'funder-onboarding',
    loadChildren: () =>
      import('./funder-onboarding/funder-onboarding.module').then(
        (m) => m.FunderOnboardingModule
      ),
      canActivate: [AuthguardWebService],
  },
  {
    path: 'guest-onboarding',
    loadChildren: () =>
      import('./guest-onboarding/guest-onboarding.module').then(
        (m) => m.GuestOnboardingModule
      ),
      canActivate: [AuthguardWebService],
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'landingpage',
    pathMatch: 'full',
  },
];


const routerOptions: ExtraOptions = {
  useHash: true,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
exports: [RouterModule],
})
export class AppRoutingModule {}
