import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
declare const Pusher: any;
import * as serverConfig from '../../../../providers/config';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusher: any;
  messagesChannel: any;

  constructor() {
    var serverType = serverConfig.SERVERTYPE;
    let redirectUrl: string;

    if (serverType == 'DEVELOPMENT') {
      redirectUrl = serverConfig.SERVERURLDEV;
    } else if (serverType == 'TESTING') {
      redirectUrl = serverConfig.SERVERURLTEST;
    } else if (serverType == 'UAT') {
      redirectUrl = serverConfig.SERVERURLUAT;
    } else if (serverType == 'PRODUCTION') {
      redirectUrl = serverConfig.SERVERURLPROD;
    } else if (serverType == 'UNITTESTING') {
      redirectUrl = serverConfig.SERVERURLUTEST;
    }

    this.pusher = new Pusher(environment.pusher.key, {
      // cluster: 'ap2',
      cluster: environment.pusher.cluster,
      authEndpoint: redirectUrl + 'api/chatPusher/pusher',
    });
  }

  /**
   * pusher to connect with backend
   * @param id channel id
   */
  pusherMessage(id: any): any {
    this.messagesChannel = this.pusher.subscribe(id);
  };


  /**
 * Trigger an event to notify the other user
 * @param channelId The channel ID of the user to notify
 * @param eventName The name of the event to trigger
 * @param data The data to send with the event
 */

  notifyUser(channelId: string, eventName: string, data: any) {
    const clientEventName = `client-${eventName}`;

    // Log channel and event for debugging
    console.log(`Subscribing to channel: ${channelId}`);
    console.log(`Triggered event ${clientEventName} on channel ${channelId} with data:`, data);

    // Subscribe to the channel
    const channel = this.pusher.subscribe(channelId);

    // Trigger the event
    setTimeout(() => {
      channel.trigger(clientEventName, data);

    }, 500);
  };








}
