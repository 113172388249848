import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import * as configs from '../../src/providers/config';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgGanttEditorModule } from 'ng-gantt';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutoFocusDirectiveDirective } from './auto-focus-directive.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { IonicSelectableModule } from 'ionic-selectable';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from './shared/shared.module';



function getFaceBookappId() {
  var type = configs.SERVERTYPE;
  if (type == "DEVELOPMENT") {
    return configs.FACEBOOKAPPIDDEV;
  } else if (type == "UNITTESTING") {
    return configs.FACEBOOKAPPIDUTEST;
  } else if (type == "TESTING") {
    return configs.FACEBOOKAPPIDTEST;
  } else if (type == "UAT") {
    return configs.FACEBOOKAPPIDUAT;
  } else if (type == "PRODUCTION") {
    return configs.FACEBOOKAPPIDPROD;
  }

}

function getGoogleappId() {
  var type = configs.SERVERTYPE;
  if (type == "DEVELOPMENT") {
    return configs.GOOGLEAPPIDDEV;
  } else if (type == "UNITTESTING") {
    return configs.GOOGLEAPPIDUTEST;
  } else if (type == "TESTING") {
    return configs.GOOGLEAPPIDTEST;
  } else if (type == "UAT") {
    return configs.GOOGLEAPPIDUAT;
  } else if (type == "PRODUCTION") {
    return configs.GOOGLEAPPIDPROD;
  }

}

@NgModule({
  declarations: [AppComponent, AutoFocusDirectiveDirective],
  entryComponents: [],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    NgGanttEditorModule,
    NgxOrgChartModule,
    CommonModule,
    FormsModule,
    NgbModule,
    AngularEditorModule,
    IonicSelectableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB_dn8kShULFzOZMlGw7iBXRn5z-jW0Iak&libraries=places&language=en-US',
      libraries: ["places"]
    }),
    // IonicModule.forRoot(),
    IonicModule.forRoot({
      animated: false
    }),
    NgCircleProgressModule.forRoot({
      "radius": 18,
      "outerStrokeWidth": 4,
      "innerStrokeWidth": 2,
      "showBackground": false,
      "startFromZero": false,
     "showInnerStroke":false,
      "outerStrokeColor": "#0B58B0",
       "animation": true,
     "titleFontSize" : '11',
      "titleColor":"#0B58B0",
      "unitsColor":"#0B58B0",
      "showSubtitle":false,
       "space" : 0
    }),
    AppRoutingModule,
    ModalModule.forRoot(),
    HttpClientModule,
    SocialLoginModule,
    NgxIntlTelInputModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    SharedModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    BsModalRef,
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            getGoogleappId()
          )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(getFaceBookappId())
        }
      ]
    } as SocialAuthServiceConfig,
  },
  { provide: 'windowObject', useValue: window },

  ],
  exports: [
    NgCircleProgressModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
