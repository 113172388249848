import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {
  private callbacks: Map<string, (data: any) => void> = new Map();

  // Register a callback for a specific notification type
  registerCallback(type: string, callback: (data: any) => void) {
    if (this.callbacks.has(type)) {
      console.warn(`Overwriting existing callback for type: ${type}`);
    }
    this.callbacks.set(type, callback);
    console.log(`Registered callback for type: ${type}`);
  }

  // Send a notification to trigger callbacks
  sendNotification(notification: any) {
    console.log('Triggering callback for notification:', notification);
    const callback = this.callbacks.get(notification.notificationType);
    if (callback) {
      console.log(`Executing callback for notification type: ${notification.notificationType}`);
      callback(notification);
    } else {
      console.warn(`No callback registered for notification type: ${notification.notificationType}`);
    }
  }
}

