// export const SERVER_URL_DEV = "http://localhost:4000";

// set type DEVELOPMENT ,UNITTESTING, TESTING , UAT , PRODUCTION, UNITTESTING

// export const SERVERTYPE: string = 'TESTING';

// type MOBILE WEB

export const SERVERTYPE = 'TESTING';
export const APPLICATIONTYPE = 'WEB';

export const SERVERURLDEV = 'http://localhost:4200/';
export const SERVERURLUTEST = 'https://collabapp-utest.crecientech.com/';
export const SERVERURLTEST = 'https://collabapp-test.inventfunds.com/';
export const SERVERURLPROD = 'https://collabapp-prod.crecientech.com/';
export const SERVERURLUAT = 'https://collabapp-uat.crecientech.com/';
export const SERVERURL = 'https://collabapp-test.inventfunds.com/';

export const GOOGLEAPPIDDEV =
  '92055369737-1vbk41atli1plh5hvj58rc2fobfvq1tr.apps.googleusercontent.com';
export const GOOGLEAPPIDUTEST =
  '347739600118-h0kpkme1iedrubbedn8pa2uf1e32r2mn.apps.googleusercontent.com';
export const GOOGLEAPPIDTEST =
  '92055369737-1vbk41atli1plh5hvj58rc2fobfvq1tr.apps.googleusercontent.com';
export const GOOGLEAPPIDUAT =
  '92055369737-1vbk41atli1plh5hvj58rc2fobfvq1tr.apps.googleusercontent.com';
export const GOOGLEAPPIDPROD =
  '92055369737-1vbk41atli1plh5hvj58rc2fobfvq1tr.apps.googleusercontent.com';

export const FACEBOOKAPPIDDEV = '382714076049470';
export const FACEBOOKAPPIDUTEST = '382714076049470';
export const FACEBOOKAPPIDTEST = '382714076049470';
export const FACEBOOKAPPIDUAT = '352961552659649';
export const FACEBOOKAPPIDPROD = '352961552659649';

export const TWITTERAPPIDDEV = '1326445273397170176';
export const TWITTERAPPIDUTEST = '1072503943790497798';
export const TWITTERAPPIDTEST = '1326445273397170176';
export const TWITTERAPPIDUAT = '1326445273397170176';
export const TWITTERAPPIDPROD = '1326445273397170176';

export const APPLECLIENTIDDEV = 'com.collabapp.inventfunds.utestservice';
export const APPLECLIENTIDUTEST = 'com.collabapp.inventfunds.utestservice';
export const APPLECLIENTIDTEST = 'com.collabapp.inventfund.service';
export const APPLECLIENTIDUAT = '';
export const APPLECLIENTIDPROD = '';

export const APPLE_REDIRECT_LOGIN_URL_DEV =
  'https://6de3fc36949e.ngrok.io/api/apple/signIn';
export const APPLE_REDIRECT_LOGIN_URL_UTEST =
  'https://collabapp-utest.crecientech.com/api/apple/signIn';
export const APPLE_REDIRECT_LOGIN_URL_TEST =
  'https://collabapp-test.inventfunds.com/api/apple/signIn';
export const APPLE_REDIRECT_LOGIN_URL_UAT = '';
export const APPLE_REDIRECT_LOGIN_URL_PROD = '';

export const APPLE_REDIRECT_SIGNUP_URL_DEV =
  'https://6de3fc36949e.ngrok.io/api/apple/signUp';
export const APPLE_REDIRECT_SIGNUP_URL_UTEST =
  'https://collabapp-utest.crecientech.com/api/apple/signUp';
export const APPLE_REDIRECT_SIGNUP_URL_TEST =
  'https://collabapp-test.inventfunds.com/api/apple/signUp';
export const APPLE_REDIRECT_SIGNUP_URL_UAT = '';
export const APPLE_REDIRECT_SIGNUP_URL_PROD = '';

export const APPLE_SCOPE = 'name email';
export const APPLE_STATE = 'what ever string to be remembered';

export const TWITTERTIMELINEDEV =
  'https://twitter.com/crecientech?ref_src=twsrc%5Etfw';
export const TWITTERTIMELINEUTEST =
  'https://twitter.com/crecientech?ref_src=twsrc%5Etfw';
export const TWITTERTIMELINETEST =
  'https://twitter.com/inventfunds?ref_src=twsrc%5Etfw';
export const TWITTERTIMELINEUAT =
  'https://twitter.com/inventfunds?ref_src=twsrc%5Etfw';
export const TWITTERTIMELINEPROD =
  'https://twitter.com/inventfunds?ref_src=twsrc%5Etfw';

export const SERVERREDRECTURLDEV = 'http://localhost:4000/api/auth/getToken';
export const SERVERREDRECTURLUTESTIN =
  'https://collabapp-utest.crecientech.com/api/auth/getToken';
export const SERVERREDRECTURLTESTIN =
  'https://collabapp-test.inventfunds.com/api/auth/getToken';
export const SERVERREDRECTURLUAT =
  'https://collabapp-uat.crecientech.com/api/auth/getToken';
export const SERVERREDRECTURLPROD =
  'https://collabapp-prod.crecientech.com/api/auth/getToken';

export const SERVERREDRECTURLCHANGEPASSWORDDEV =
  'http://localhost:4000/api/auth/getTokenChangePassword';
export const SERVERREDRECTURLCHANGEPASSWORDUTESTIN =
  'https://collabapp-utest.crecientech.com/api/auth/getTokenChangePassword';
export const SERVERREDRECTURLCHANGEPASSWORDTESTIN =
  'https://collabapp-test.inventfunds.com/api/auth/getTokenChangePassword';
export const SERVERREDRECTURLCHANGEPASSWORDUAT =
  'https://collabapp-uat.crecientech.com/api/auth/getTokenChangePassword';
export const SERVERREDRECTURLCHANGEPASSWORDPROD =
  'https://collabapp-prod.crecientech.com/api/auth/getTokenChangePassword';

//MongoDB CRUD
export const RETRIEVEMONGOALL = SERVERURL + 'api/mongoDb/retrieveAll';
export const RETRIEVEMONGOALLANDMASK = SERVERURL + 'api/mongoDb/retrieveAndMask';
export const RETRIEVEMONGOONE = SERVERURL + 'api/mongoDb/retrieveOne';
export const CREATEMONGODATA = SERVERURL + 'api/mongoDb/createData';
export const UPDATEMONGODATA = SERVERURL + 'api/mongoDb/updateData';
export const UPDATEMONGOEVENT = SERVERURL + 'api/mongoDb/updateEvent';

export const DELETEMONGODATA = SERVERURL + 'api/mongoDb/deleteData';
export const DELETEMONGOEVENT = SERVERURL + 'api/mongoDb/deleteEvent';

// chat panel
export const CREATECHATDATA = SERVERURL + 'api/chatPusher/createChat';
export const CREATECHANNEL = SERVERURL + 'api/chatPusher/createChannel';
export const GETCONTACTS = SERVERURL + 'api/chatPusher/retriveContacts';
export const RETRIVECHATS = SERVERURL + 'api/chatPusher/retriveAllMessages';
export const UPDATEMESSAGESTATUS = SERVERURL + 'api/chatPusher/updateMessageStatus';
export const UPDATECHANNELID = SERVERURL + 'api/chatPusher/updateChannelId';
export const GETACTIVECHANNELCOUNT = SERVERURL + 'api/chatPusher/activeChannelCount';

export const CREATECHATDATAMANY = SERVERURL + 'api/chatPusher/createContacts';
export const CREATECHATDATAIDEA = SERVERURL + 'api/chatPusher/createIdeaChannel';
export const GETCONTACTSINV = SERVERURL + 'api/chatPusher/retriveContactsInv';
export const CREATECONTACTSCOLLB = SERVERURL + 'api/chatPusher/createContactForCollaborator';

// video call
export const CREATEMEETING = SERVERURL + 'api/videoCall/createMeeting';
export const GENERATESIGNATURE = SERVERURL + 'api/videoCall/generateSignature';

//Send otp
// export const SENDOTP = SERVERURL + 'api/email/sendMobileOtp';
export const SENDOTP = SERVERURL + 'api/email/sendOtp';
export const SENDPDF = SERVERURL + 'api/email/sendPdf';
// signup and signin
export const SIGNIN = SERVERURL + 'api/user/mongoSignIn';
export const SIGNUP = SERVERURL + 'api/user/mongoSignUp';
//password
export const CHANGEPASSWORD = SERVERURL + 'api/user/mongoChangePassword';
//Uplaod image
export const UPLOADTOS3MOBILE = SERVERURL + 'api/mobAttachment/fileUpload';
//role update
export const UPDATEORGANDROLE = SERVERURL + 'api/user/updateOrgAndRole';
//Token
export const GETTOKEN = SERVERURL + 'api/user/getToken';
//Forgotpassword
export const FORGOTPASSWORD = SERVERURL + 'api/user/forgotPassword';
//Retrieve lookup
export const RETRIEVELOOKUP = SERVERURL + 'api/lookUp/retrieveLookupData';

export const UPDATEATTACHMENT = SERVERURL + 'api/attachment/updateAttachment';
export const SINGLEATTACHMENT = SERVERURL + 'api/attachment/singleAttachment';
export const GETSIGNEDATTACHMENT = SERVERURL + 'api/attachment/getSignedData';

export const RETRIEVEALLUSER =
  SERVERURL + 'api/user/retrieveAllExceptLoginUser';

export const SENDEVENTEMAIL = SERVERURL + 'api/email/createCalendarevent';
export const SENDINVITEEMAIL = SERVERURL + 'api/email/sendMail';

export const UPDATEWORKSUMMARY = SERVERURL + 'api/user/updateWorkSummary';
export const UPLOADS3PROFILE =
  SERVERURL + 'api/attachment/uploadDirectToS3Profile';

export const COMPARECURRENTPASSWORD = SERVERURL + 'api/user/comparePassword';
export const UPDATEPWD = SERVERURL + 'api/forgotPassword/updatePwd';
export const DELETEATTACHMENT = SERVERURL + 'api/attachment/deleteAttachment';

export const GETPROFILEDATA = SERVERURL + 'api/profile/getProfileData';
export const ADDCUSTOMSKILL = SERVERURL + 'api/profile/addCustomSkill';
export const ADDCUSTOMSKILLINPERSON =
  SERVERURL + 'api/profile/addCustomSkillInPersonTbl';
