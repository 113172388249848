import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardWebService implements CanActivate {

  constructor(
    public router: Router,
    public appStateService: AppStateService,
    public activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
  ) { }

  restrictedRoutes = [
    'sign-up',
    'verification',
    'sign-in',
    'user-details',
    // 'category'

  ];
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log("canActivate called!")
    let routeData = route.data[0] as string;
    localStorage.setItem('dashboardUrl', state.url);
    if (localStorage.getItem('profile')) {
      if (localStorage.getItem('profile') && this.restrictedRoutes.includes(routeData)) {
        return false;
      }
      return true;
    } else if (localStorage.getItem('profile') == null && this.restrictedRoutes.includes(routeData)) {
      return true
    } 
    else {
      this.navCtrl.navigateBack(['/landingpage']);
      return true;
         }
  }
}
