import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import panzoom from 'panzoom';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnInit {
  @ViewChild('officeDoc') officeDoc: ElementRef;
  @Input() fileUrl: string;
  @Output() close = new EventEmitter<void>();
  spinner: boolean;

  @ViewChild('panzoomContainer') panzoomContainer: ElementRef;

  panZoomInstance: any; // Variable to hold panzoom instance

  constructor() { }

  ngOnInit() {
    this.showSpinner();
  }

  isPdf(url: string): boolean {
    return url.toLowerCase().endsWith('.pdf');
  };

  closeViewer() {
    this.close.emit();
  };

  showSpinner() {
    this.spinner = true;
    setTimeout(() => {
      this.spinner = false;
    }, 5000);  // remove spinner after 5 seconds
  };

  ngAfterViewInit() {
    this.initPanZoom();
  };

  // initPanZoom() {
  //   this.panZoomInstance = panzoom(this.panzoomContainer.nativeElement, {
  //     zoomDoubleClickSpeed: 1, // Adjust zoom speed on double click
  //     maxZoom: 3, // Maximum zoom level
  //     minZoom: 0.5, // Minimum zoom level
  //     bounds: true // Enable content boundary within the container
  //   });
  // }

  // zoomIn() {
  //   if (this.panZoomInstance) {
  //     this.panZoomInstance.zoomIn();
  //   }
  // }

  // zoomOut() {
  //   if (this.panZoomInstance) {
  //     this.panZoomInstance.zoomOut();
  //   }
  // }

  // panZoomOnWheel(event: WheelEvent) {
  //   if (this.panZoomInstance) {
  //     this.panZoomInstance.zoomWithWheel(event);
  //   }
  // }

  panZoomOnWheel(event: WheelEvent) {
    if (this.panZoomInstance) {
      const delta = Math.max(-1, Math.min(1, (event.deltaY || -event.detail)));
      const scaleMultiplier = 1.1;
      const scaleDelta = delta > 0 ? scaleMultiplier : 1 / scaleMultiplier;
      this.panZoomInstance.zoom(event, scaleDelta);
    }
  }


  panZoomOnMouseDown(event: MouseEvent) {
    if (this.panZoomInstance) {
      this.panZoomInstance.panStart(event);
    }
  }

  panZoomOnMouseUp(event: MouseEvent) {
    if (this.panZoomInstance) {
      this.panZoomInstance.panEnd();
    }
  };


  initPanZoom() {
    this.panZoomInstance = panzoom(this.panzoomContainer.nativeElement, {
      zoomDoubleClickSpeed: 1, // Adjust zoom speed on double click
      maxZoom: 2, // Maximum zoom level
      minZoom: 0.5, // Minimum zoom level
      bounds: true // Enable content boundary within the container
    });

    // Attach event listeners for touch events
    this.panzoomContainer.nativeElement.addEventListener('touchstart', this.panZoomOnTouchStart.bind(this));
    this.panzoomContainer.nativeElement.addEventListener('touchmove', this.panZoomOnTouchMove.bind(this));
    this.panzoomContainer.nativeElement.addEventListener('touchend', this.panZoomOnTouchEnd.bind(this));
  }

  zoomIn() {
    if (this.panZoomInstance) {
      this.panZoomInstance.zoomIn();
    }
  }

  zoomOut() {
    if (this.panZoomInstance) {
      this.panZoomInstance.zoomOut();
    }
  }

  panZoomOnTouchStart(event: TouchEvent) {
    if (this.panZoomInstance && event.touches.length === 2) {
      // Pinch gesture detected, calculate initial distance between fingers
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const initialDistance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      this.panZoomInstance.startZoom(event, { clientX: (touch1.clientX + touch2.clientX) / 2, clientY: (touch1.clientY + touch2.clientY) / 2, scale: 1 });
      this.panZoomInstance.startPan(event);
      this.panzoomContainer.nativeElement.addEventListener('touchmove', this.panZoomOnPinchMove.bind(this, initialDistance));
    } else if (this.panZoomInstance && event.touches.length === 1) {
      // Single touch, initiate pan
      this.panZoomInstance.startPan(event);
    }
  }

  panZoomOnPinchMove(initialDistance: number, event: TouchEvent) {
    if (this.panZoomInstance && event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];
      const currentDistance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
      const scale = currentDistance / initialDistance;
      this.panZoomInstance.zoom(event, scale);
    }
  }

  panZoomOnTouchMove(event: TouchEvent) {
    if (this.panZoomInstance && event.touches.length === 2) {
      // Pinch gesture, handled in touchstart and touchmove
    } else {
      // Single touch, continue pan
      this.panZoomInstance.move(event);
    }
  }

  panZoomOnTouchEnd(event: TouchEvent) {
    if (this.panZoomInstance) {
      this.panZoomInstance.endZoom(event);
      this.panZoomInstance.endPan(event);
      this.panzoomContainer.nativeElement.removeEventListener('touchmove', this.panZoomOnPinchMove.bind(this));
    }
  }


}
