import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MessagesComponent } from './messages/messages.component';

const routes: Routes = [
  {
    path: '',
    component: MessagesComponent,
  }


];


@NgModule({
  imports: [RouterModule.forChild(routes)],

  exports: [RouterModule],
})
export class SharedRoutingModule { }
