import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-date-component',
  templateUrl: './date-component.component.html',
  styleUrls: ['./date-component.component.scss'],
})
export class DateComponent implements OnInit {
  tomorrowDate;
  tomorrowDay;
  tomorrowCompleteDate;
  dayAfterTomoDate;
  dayAfterTomoDay;
  todayDate;
  todayDay;
  yesterDayDate;
  yesterDay;
  dayBefYesterday;
  dayBefYesterdayDate;
  futureDate3;
  futureDate3Day;
  futureDate4;
  futureDate4Day;
  dayAfterTomoCmpDate: any;
  futureDate3CmpDate: any;
  futureDate4CmpDate: any;
  todayCmpDate: any;
  yesterCmpDate: any;
  dayBefCmpDate: any;
  dateArray = [];
  dayArray = [];
  selecteddate;

  alwaystrue = true;
  @Output() emitDate = new EventEmitter();
  // daybef=false;

  constructor() {
    this.dayBefYesterdayDate = moment().subtract(2, 'days').format('DD');
    this.dayBefYesterday = moment().subtract(2, 'days').format('dd').charAt(0);
    this.dayBefCmpDate = moment().subtract(2, 'days').format('L');

    this.yesterDayDate = moment().subtract(1, 'days').format('DD');
    this.yesterDay = moment().subtract(1, 'days').format('dd').charAt(0);
    this.yesterCmpDate = moment().subtract(1, 'days').format('L');

    this.todayDate = moment().format('DD');
    this.todayDay = moment().format('dd').charAt(0);
    this.todayCmpDate = moment().format('L');
    this.selecteddate = this.todayCmpDate;

    this.tomorrowDate = moment().add(1, 'days').format('DD');
    this.tomorrowDay = moment().add(1, 'days').format('dd').charAt(0);
    this.tomorrowCompleteDate = moment().add(1, 'days').format('L');

    this.dayAfterTomoDate = moment().add(2, 'days').format('DD');
    this.dayAfterTomoDay = moment().add(2, 'days').format('dd').charAt(0);
    this.dayAfterTomoCmpDate = moment().add(2, 'days').format('L');

    this.futureDate3 = moment().add(3, 'days').format('DD');
    this.futureDate3Day = moment().add(3, 'days').format('dd').charAt(0);
    this.futureDate3CmpDate = moment().add(3, 'days').format('L');

    this.futureDate4 = moment().add(4, 'days').format('DD');
    this.futureDate4Day = moment().add(4, 'days').format('dd').charAt(0);
    this.futureDate4CmpDate = moment().add(4, 'days').format('L');
  }

  ngOnInit() {}

  getDate(value) {
    console.log('im here....');

    this.selecteddate = value;
    this.emitDate.emit({ val: value });
    // console.log({val:value,allValue:this.selectAll})
  }
}
