import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import { toastMessages } from 'src/utilities/toastMessage';

@Component({
  selector: 'app-clipboard-dialog',
  templateUrl: './clipboard-dialog.component.html',
  styleUrls: ['./clipboard-dialog.component.scss'],
})
export class ClipboardDialogComponent implements OnInit {

  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input() name: string;
  @Input() role: string;
  @Input() currentValue: string;
  @Input() imageUrl: string;


  isCopied : boolean = false;

  constructor(
    private appStateService: AppStateService,
  ) { }

  ngOnInit() {
    this.isCopied = false;
  }


  // showDialog() {
  //   this.visible = true;
  //   this.visibleChange.emit(this.visible);
  // }

  hideDialog() {
    this.isCopied =  false;
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  toggleCopy(){
    this.isCopied = !this.isCopied;
  }

}
